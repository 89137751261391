import {css} from '@emotion/react'

import {ProjectStatus as ProjectStatusType} from '../types'

const statusCss = css`
  font-size: 12px;
  margin: 0 0 0 6px;
  display: flex;
  align-items: center;
`

const statusDotCss = css`
  margin-right: 6px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  display: inline-block;
`

const STATUS_TEXT: Record<Status, string> = {
  pending: 'Annotation in progress.',
  success: 'NERD response complete.',
  expiring: 'This project will expire soon.',
}

const STATUS_COLOR: Record<'light' | 'dark', Record<Status, string>> = {
  dark: {
    pending: '#00B9E8',
    success: '#00B9E8',
    expiring: '#FF667F',
  },
  light: {
    pending: '#007A99',
    success: '#007A99',
    expiring: '#C61936',
  },
}

type Status = ProjectStatusType | 'expiring'

interface ProjectStatusProps {
  status: Status
  children?: React.ReactNode
  /** A proxy for whether the component is displayed on top of a light or dark background */
  variant?: 'dark' | 'light'
}

export default function ProjectStatus(props: ProjectStatusProps): JSX.Element {
  const {status, children, variant = 'dark'} = props
  const text = STATUS_TEXT[status]
  const color = STATUS_COLOR[variant][status]

  return (
    <p css={statusCss} style={{color}}>
      {children ?? (
        <>
          <span css={statusDotCss} style={{background: color}} /> {text}
        </>
      )}
    </p>
  )
}
