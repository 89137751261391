import {css} from '@emotion/react'
import {IconEdit} from '@kensho/icons'
import {useRef} from 'react'

import Input, {InputProps} from '../components/ui/controls/Input'

const containerCss = css`
  display: inline-flex;
  align-items: center;
`

const editableTextCss = css`
  > input {
    border: none;
    font-size: 24px;
    line-height: 28px;
  }

  :hover {
    > input:not(:focus) {
      background: rgba(0, 0, 0, 0.05);
    }
  }
  height: 44px;
  margin-right: 10px;
`

const iconContainerCss = css`
  display: inline-flex;
  height: 100%;
  align-items: center;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  margin-right: 16px;
`

const iconCss = css`
  color: rgba(0, 0, 0, 0.54);
`

/**
 * An input that presents itself as a title and allows the user to edit the title.
 *
 * TODO: Should eventually be consolidated into @kensho/ui.
 */
export default function EditableText(props: InputProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement | null>(null)
  return (
    <span css={containerCss}>
      <Input
        {...props}
        css={editableTextCss}
        rightElement={
          <button type="button" css={iconContainerCss} onClick={() => inputRef.current?.focus()}>
            <IconEdit size={20} css={iconCss} />
          </button>
        }
        ref={inputRef}
      />
    </span>
  )
}
