import {AnnotationStyle} from '@kensho/updoc'

import isEntityAnnotation from '../../predicates/isEntityAnnotation'
import isOutOfKnowledgeBaseAnnotation from '../../predicates/isOutOfKnowledgeBaseAnnotation'
import {ColorMap, NERDAnnotation} from '../../types'

import {NOT_FOUND_COLOR, BASE_COLOR} from './generateEntityColorMap'

interface AnnotationInteractionState {
  isHovered: boolean
  isSelected: boolean
  isColoredByNerType: boolean
  pageHasInteraction: boolean
}

export default function getStyle(
  annotation: NERDAnnotation,
  colorMap: ColorMap,
  interactionState: AnnotationInteractionState
): AnnotationStyle {
  const {isHovered, isSelected, isColoredByNerType, pageHasInteraction} = interactionState
  const style: AnnotationStyle = {}

  // Interaction z-index hierarchy: CapIQ interaction >> Any interaction >> CapIQ >> Everything else
  if (annotation.metadata.knowledgeBase === 'capiq') style.zIndex = 1
  if (isHovered || isSelected) style.zIndex = 2
  if (annotation.metadata.knowledgeBase === 'capiq' && (isHovered || isSelected)) style.zIndex = 3

  if (isColoredByNerType) {
    const colorVariant = colorMap[annotation.metadata.nerType]

    style.backgroundColor =
      isHovered || isSelected || !pageHasInteraction ? colorVariant.normal : colorVariant.light
    return style
  }

  if (isOutOfKnowledgeBaseAnnotation(annotation)) {
    style.backgroundColor = isHovered || isSelected ? NOT_FOUND_COLOR.normal : NOT_FOUND_COLOR.light
    return style
  }

  if (isEntityAnnotation(annotation)) {
    style.backgroundColor =
      pageHasInteraction && !(isHovered || isSelected) ? BASE_COLOR.light : BASE_COLOR.normal
    return style
  }

  return style
}
