import {css} from '@emotion/react'
import {useContext, useMemo} from 'react'

import {Size} from '../types'

import DisplayGroupContext, {DisplayGroup} from './DisplayGroupContext'

function getDisabled(context: DisplayGroup | null, disabled?: boolean): boolean {
  if (context?.type === 'label') {
    return disabled || context.disabled
  }
  return disabled || false
}

function getSize(context: DisplayGroup | null, size?: Size): Size {
  if (size) {
    return size
  }
  if (context?.type === 'label') {
    return context.size
  }
  return 'medium'
}

export interface ControlGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Controls in the group. */
  children: React.ReactNode

  /** Space-separated list of classes to pass to the underlying element. */
  className?: string

  /**
   * Whether to disable interactivity.
   *
   * @default false
   */
  disabled?: boolean

  /**
   * Whether to expand the group and its contents to fill available horizontal space.
   *
   * @default false
   */
  fill?: boolean

  /**
   * The size of the controls in the group.
   *
   * @default 'medium'
   */
  size?: Size

  /**
   * Whether to group the controls vertically rather than horizontally.
   *
   * @default false
   */
  vertical?: boolean
}

/**
 * Groups together multiple controls which function as a logical unit. Natively supports and styles
 * Buttons, Inputs, and Selects.
 */
export default function ControlGroup(props: ControlGroupProps): JSX.Element {
  const {
    children,
    disabled: propDisabled,
    fill = false,
    size: propSize,
    vertical = false,
    ...rest
  } = props
  const group = useContext(DisplayGroupContext)
  const disabled = getDisabled(group, propDisabled)
  const size = getSize(group, propSize)

  const cssControlGroup = css`
    align-items: stretch;
    display: ${fill ? 'flex' : 'inline-flex'};
    flex-direction: ${vertical ? 'column' : 'row'};
    width: ${fill ? '100%' : 'auto'};
  `

  const controlGroup = useMemo<DisplayGroup>(
    () => ({type: 'control', disabled, fill, size, vertical}),
    [disabled, fill, size, vertical]
  )

  return (
    <DisplayGroupContext.Provider value={controlGroup}>
      <div css={cssControlGroup} role="group" {...rest}>
        {children}
      </div>
    </DisplayGroupContext.Provider>
  )
}
