import {ColorMap, KnowledgeBaseAnnotations} from '../../types'

export const NOT_FOUND_COLOR = {normal: '#c7c7c7', light: '#eeeeee'}
export const BASE_COLOR = {normal: '#00B9E8', light: '#DAF8FF'}
export const DISABLED_COLOR = '#f5f5f5'

// TODO: There may be a desire to do a constant mapping from NER Type => color
const COLORS = [
  BASE_COLOR,
  {normal: '#F74DFF', light: '#FDDAFF'},
  {normal: '#958CFF', light: '#EBE6FF'},
  {normal: '#FFBA26', light: '#FFF8E6'},
  {normal: '#FF667F', light: '#FFE6EA'},
  {normal: '#23C258', light: '#EBFFE6'},
  {normal: '#88E3FA', light: '#DAF8FF'},
  {normal: '#FA96FF', light: '#FDDAFF'},
  {normal: '#B5AFFC', light: '#EBE6FF'},
  {normal: '#FCE4AF', light: '#FFF8E6'},
  {normal: '#FF91A4', light: '#FFE6EA'},
  {normal: '#85F7AB', light: '#EBFFE6'},
]

export default function generateEntityColorMap(results: KnowledgeBaseAnnotations[]): ColorMap {
  const types = new Set(
    ...[results.flatMap((kbResults) => kbResults.annotations.map((ann) => ann.nerType))]
  )

  return [...types].reduce((acc, type, idx) => {
    acc[type] = COLORS[idx % COLORS.length]
    return acc
  }, {} as ColorMap)
}
