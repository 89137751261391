import {Component} from 'react'
import {useLogger, Logger} from '@kensho/lumberjack'
import {IconExclamation} from '@kensho/icons'
import {css} from '@emotion/react'

import NonIdealState from './ui/displays/NonIdealState'

const boundaryCss = css`
  margin-top: 24px;

  svg {
    width: 30px;
    height: 30px;
  }
`

interface ErrorBoundaryState {
  hasError: boolean
}

interface ErrorBoundaryProps {
  children: React.ReactNode
  log: Logger
}

class ErrorBoundaryWithLogger extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public static getDerivedStateFromError(): Partial<ErrorBoundaryState> {
    return {hasError: true}
  }

  public state: ErrorBoundaryState = {
    hasError: false,
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo): void {
    const {log} = this.props
    log.error(error, info)
  }

  public render(): React.ReactNode {
    const {children} = this.props
    const {hasError} = this.state
    if (!hasError) return children
    return (
      <NonIdealState
        css={boundaryCss}
        icon={IconExclamation}
        title="Oops, something went wrong"
        description="Please refresh to try again"
      />
    )
  }
}
export default function ErrorBoundary(props: Omit<ErrorBoundaryProps, 'log'>): JSX.Element {
  const {children} = props
  const log = useLogger()
  return <ErrorBoundaryWithLogger log={log}>{children}</ErrorBoundaryWithLogger>
}
