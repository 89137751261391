import {css} from '@emotion/react'

import {IconComponent} from '../types'

export interface NonIdealStateProps {
  /** Interactive content constituting an action which the user can take to resolve the state. */
  action?: React.ReactNode
  /**
   * Additional information or content, rendered after `description`.
   * Provided as an escape hatch when other props are insufficient.
   */
  children?: React.ReactNode
  /** Space-separated list of classes to pass to the underlying element. */
  className?: string
  /** Longer explanation of why content is not visible. */
  description?: React.ReactNode
  /** Short summary of why content is not visible. */
  title?: React.ReactNode
  /** Icon to render above the title. */
  icon?: IconComponent
}

/**
 * Informs the user of why expected content is not currently visible.
 * For example, because:
 * 1. Content is loading or being requested.
 * 2. An error has occurred, preventing the view from rendering.
 * 3. The view represents a collection that is currently empty.
 *
 * If possible, it also provides the user with an action to perform to
 * resolve the non-ideal state.
 *
 * @example Given an empty todo list, a "create a todo" button could be displayed.
 */
export default function NonIdealState(props: NonIdealStateProps): JSX.Element {
  const {action, children, description, title, icon: Icon, ...rest} = props

  const cssNonIdealState = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  `

  return (
    <div css={cssNonIdealState} {...rest}>
      {Icon != null && <Icon />}
      {title != null && <h4>{title}</h4>}
      {(children != null || description != null) && (
        <div>
          {description}
          {children}
        </div>
      )}
      {action != null && <div>{action}</div>}
    </div>
  )
}
