import {css} from '@emotion/react'

const cssFooterActions = css`
  display: flex;
  justify-content: flex-end;

  > button:not(:first-of-type) {
    margin-left: 12px;
  }
`

export interface DialogFooterActionsProps {
  children?: React.ReactNode
}

/**
 * Styles buttons inside a footer to present themselves as actions for the Dialog.
 *
 * @see DialogFooter for where to use this component.
 * @see Dialog for more context on this component.
 */
export default function DialogFooterActions(props: DialogFooterActionsProps): JSX.Element {
  const {children} = props
  return <div css={cssFooterActions}>{children}</div>
}
