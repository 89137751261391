import {useEffect} from 'react'

/** Returns a Promise that resolves after `delay` ms. */
function sleep(delay: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
}

/**
 * Repeatedly invokes an asynchronous callback with a minimum time delay between each invocation.
 *
 * The callback will not be re-invoked until both the delay has passed and the previous callback has settled.
 *
 * @param callback The asynchronous callback to invoke on each interval.
 * This function should be stable with respect to its parameters.
 * It should not have side effects such as setting state.
 *
 * @param delay The minimum number of milliseconds that should pass between invocations.
 * If `null`, the callback is never invoked.
 *
 * @param onResolve An optional callback to invoke when the callback resolves.
 * This function should be stable with respect to its parameters.
 *
 * @param onReject An optional callback to invoke when the callback rejects.
 * This function should be stable with respect to its parameters.
 *
 */
export default function useAsyncInterval<T, E>(
  callback: () => Promise<T>,
  delay: number | null,
  onResolve?: (value: T) => void,
  onReject?: (error: E) => void
): void {
  useEffect(() => {
    if (delay === null) return undefined

    let isCurrent = true

    const invokeNextInterval = (): void => {
      const delayPromise = sleep(delay)
      const callbackPromise = callback()

      // call the appropriate resolve/reject handlers as soon as the callback settles
      callbackPromise.then(
        (value) => isCurrent && onResolve?.(value),
        (error) => isCurrent && onReject?.(error)
      )

      // only invoke the next interval once both the delay has passed and the callback has settled
      Promise.allSettled([callbackPromise, delayPromise]).then(
        () => isCurrent && invokeNextInterval()
      )
    }

    invokeNextInterval()

    return () => {
      isCurrent = false
    }
  }, [callback, delay, onResolve, onReject])
}
