export const URLS = {
  HOME: 'https://services.kensho.com',
  CONTACT_FORM: `https://kensho.com/contact/solutions?product=nerd`,
  PRIVACY_POLICY: 'https://kensho.com/privacy',
  TERMS_OF_USE: 'https://kensho.com/terms-of-use',
  TERMS_OF_SERVICE: 'https://kensho.com/terms-of-service',
  FREE_TRIAL: 'https://services.kensho.com/free-trial?product=nerd',
}

export const ENDPOINTS = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  ME: '/me',
  ACCESS_TOKEN_REFRESH: '/oauth2/refresh',
}

export const HEADER_HEIGHT = 50
export const MARKETPLACE_IDP = '0oa233h7mov7U7gD14x7'

export const EMAILS = {
  SUPPORT: 'support@kensho.com',
}
