import {createContext} from 'react'

export interface User {
  email: string
  token: string
  refreshToken: string
  entitlements: string[]
}

export interface UserContextInterface {
  user?: User
  logout: () => void
  _state: 'known' | 'unknown'
}

export default createContext<UserContextInterface>({
  logout: () => null,
  _state: 'unknown',
})
