import {colord} from 'colord'

import {PRIMARY, DANGER, BASE_BACKGROUND} from '../constants/theme'
import {
  DEPTH_OPACITY,
  DEPTH_OPACITY_WITH_INTENT,
  DISABLED_TEXT_WITH_INTENT_OPACITY,
} from '../constants/values'
import {Depth} from '../depth/DepthContext'
import {Intent, Theme} from '../types'
import assertNever from '../utils/assertNever'
import applyDepth from '../depth/applyDepth'

export interface BackgroundColorOptions {
  baseBackgroundColor?: string
  depth?: Depth
  disabled?: boolean
  intent?: Intent
  minimal?: boolean
  theme: Theme
}

function getBaseBackgroundColor(options: BackgroundColorOptions): string {
  const {theme, intent} = options
  switch (intent) {
    case undefined:
      return BASE_BACKGROUND[theme]
    case 'primary':
      return PRIMARY[theme]
    case 'danger':
      return DANGER[theme]
    default:
      return assertNever(intent)
  }
}

export default function getBackgroundColor(options: BackgroundColorOptions): string {
  const {baseBackgroundColor = 'none', depth = 0, disabled, intent, minimal} = options
  if (depth > 0) {
    throw new Error(`Depth of ${depth}, greater than 0, is not allowed for background colors.`)
  }
  const baseColor = getBaseBackgroundColor(options)
  if (disabled && intent && !minimal) {
    return colord(baseColor).alpha(DISABLED_TEXT_WITH_INTENT_OPACITY).toHex()
  }
  if (intent && !minimal) {
    // Lighten/darken by multiplying luminosity by a constant ratio.
    return applyDepth(baseColor, depth)
  }
  if (depth === 0) {
    return baseBackgroundColor
  }
  const opacity = intent ? DEPTH_OPACITY_WITH_INTENT : DEPTH_OPACITY
  return colord(baseColor)
    .alpha(opacity * -depth)
    .toHex()
}
