import {Annotation} from '@kensho/updoc'

import {NERDAnnotation} from '../types'

import isEntityAnnotation from './isEntityAnnotation'
import isOutOfKnowledgeBaseAnnotation from './isOutOfKnowledgeBaseAnnotation'

export default function isNERDAnnotation(annotation: Annotation): annotation is NERDAnnotation {
  return isEntityAnnotation(annotation) || isOutOfKnowledgeBaseAnnotation(annotation)
}
