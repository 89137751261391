import {Outlet} from 'react-router-dom'
import {css} from '@emotion/react'
import {IconExclamation} from '@kensho/icons'

import Spinner from '../components/Spinner'
import NonIdealState from '../components/ui/displays/NonIdealState'
import useProjects from '../hooks/useProjects'
import ProjectsContext from '../providers/ProjectsContext'

import ProjectNavLink from './ProjectNavLink'

const layoutCss = css`
  display: flex;
  height: 100%;
`

const sidebarCss = css`
  background: #2a2d2d;
  box-shadow: inset -1px 0px 0px rgba(0, 0, 0, 0.25);
  width: 250px;
  padding: 12px 0;
  flex-shrink: 0;
  color: rgba(255, 255, 255, 0.76);

  h2 {
    margin: 0 18px 12px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 20px;
  }
`

const contentCss = css`
  flex: 1;
`

const nonIdealStateCss = css`
  color: inherit;

  svg {
    width: 30px;
    height: 30px;
  }
`

const projectsListCss = css`
  list-style: none;
  margin: 0;
  padding: 0;

  a {
    font-size: 16px;
    height: 100%;
    display: flex;
    align-items: center;
    min-height: 50px;
    padding: 12px 24px;
    color: inherit;

    &.active,
    :hover {
      background: #242727;
    }

    li {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
`

export default function UserProjects(): JSX.Element {
  const projects = useProjects()
  const {asyncProjects} = projects
  return (
    <article css={layoutCss}>
      <aside css={sidebarCss}>
        <h2>My NERD Projects</h2>
        {asyncProjects.status === 'pending' && <NonIdealState title={<Spinner size={30} />} />}
        {asyncProjects.status === 'error' && (
          <NonIdealState
            css={nonIdealStateCss}
            icon={IconExclamation}
            title="An error occurred when fetching your projects."
            description="Try refreshing the page."
          />
        )}
        {asyncProjects.status === 'ready' && (
          <ul css={projectsListCss}>
            {asyncProjects.value.map((project) => (
              <ProjectNavLink key={project.jobId} project={project} />
            ))}
          </ul>
        )}
      </aside>
      <article css={contentCss}>
        <ProjectsContext.Provider value={projects}>
          <Outlet />
        </ProjectsContext.Provider>
      </article>
    </article>
  )
}
