import {css} from '@emotion/react'

const metadataSectionCss = css`
  margin-bottom: 24px;

  summary {
    list-style: none;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    font-size: 10px;
    cursor: pointer;
  }
  summary::after {
    content: '►';
    font-size: 10px;
    margin-left: 6px;
  }
  &[open] summary::after {
    content: '▼';
    font-size: 10px;
  }

  h3 {
    margin: 0;
    color: rgba(0, 0, 0, 0.54);
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    display: inline-block;
  }
`

export interface MetadataItemProps<T> {
  title: React.ReactNode
  items: T[]
  itemRenderer: (item: T, idx: number) => React.ReactNode
  isCollapsable?: boolean
  defaultOpen?: boolean
}

export default function MetadataItem<T>(props: MetadataItemProps<T>): JSX.Element {
  const {title, items, itemRenderer, isCollapsable = false, defaultOpen = true} = props

  if (isCollapsable) {
    return (
      <details open={defaultOpen} css={metadataSectionCss}>
        <summary>
          <h3>{title}</h3>
        </summary>
        {items.map(itemRenderer)}
      </details>
    )
  }

  return (
    <section css={metadataSectionCss}>
      <h3>{title}</h3>
      {items.map(itemRenderer)}
    </section>
  )
}
