import {TEXT_DISABLED, TEXT_INVERTED, TEXT_INVERTED_DISABLED, TEXT_NORMAL} from '../constants/theme'
import {Intent, Theme} from '../types'

import getBackgroundColor from './getBackgroundColor'

// TODO: Consider consolidating options into some generic set
export interface TextColorOptions {
  disabled?: boolean
  intent?: Intent
  minimal?: boolean
  theme: Theme
}

export default function getTextColor(options: TextColorOptions): string {
  const {disabled, intent, minimal, theme} = options
  const baseColor = disabled ? TEXT_DISABLED[theme] : TEXT_NORMAL[theme]
  if (intent) {
    if (minimal) {
      return getBackgroundColor({theme, intent, depth: -1, disabled})
    }
    return disabled ? TEXT_INVERTED_DISABLED[theme] : TEXT_INVERTED[theme]
  }
  return baseColor
}
