import {css} from '@emotion/react'

import {NERDProjectMetadata} from '../../types'

import MetadataItem from './MetadataItem'
import ResultsLayout from './ResultsLayout'

const DUMMY_PARAGRAPHS = [
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin malesuada est ac molestie venenatis. Vivamus rhoncus faucibus feugiat. Vivamus rutrum viverra mattis. Maecenas iaculis mauris ullamcorper, pulvinar risus vitae, iaculis eros. Maecenas maximus ipsum dolor, ac fringilla augue lobortis quis. Vestibulum bibendum, lectus ut semper bibendum, ligula augue suscipit mi, ut tincidunt enim urna ut eros. Etiam nec ipsum ut elit condimentum finibus vitae quis enim. Fusce finibus mollis laoreet. Quisque sit amet risus vitae orci tristique varius quis nec odio. Phasellus facilisis lectus ut ornare sollicitudin. Donec efficitur eu tellus pharetra ultrices. Nullam aliquam libero velit, non commodo ligula porta at. Ut pharetra commodo dui, eu consectetur augue porttitor sit amet. Praesent hendrerit luctus pretium. Maecenas non feugiat turpis, ac viverra purus. Suspendisse tincidunt leo sit amet imperdiet convallis.',
  'Maecenas sit amet risus maximus, efficitur dolor id, placerat leo. Nunc quis hendrerit felis, vel laoreet purus. Donec egestas leo vitae lacus scelerisque gravida. Etiam pulvinar lacus id quam volutpat, sed euismod felis dictum. Aenean viverra ornare ultricies. Etiam non consequat arcu. Sed tempor ornare ipsum eu pulvinar.',
  'Integer imperdiet tempus facilisis. Maecenas ornare est ac nunc laoreet tempor. Nam ultrices quam a lorem eleifend ultricies. Nullam finibus odio at magna tincidunt, at ultricies dolor volutpat. Donec nec nisi a tortor suscipit ultricies quis eget ipsum. Aliquam non tortor ligula. Mauris et cursus erat. Quisque vel sodales mi, non tincidunt mi. Nunc ac erat sollicitudin, rhoncus lorem sit amet, feugiat orci. Donec accumsan orci massa, sed congue lorem consectetur id. Maecenas sagittis sagittis ipsum, eget venenatis ante. Vivamus tortor ante, sollicitudin at molestie quis, venenatis non justo.',
  'Praesent pulvinar egestas porta. Praesent id lacinia lorem. Aenean laoreet cursus est, ac sodales nisi. Donec eget dolor non massa tempus convallis vel et sem. Maecenas vehicula sodales tristique. Suspendisse est ante, condimentum sit amet ipsum porttitor, mollis luctus arcu. Aenean tristique ultrices hendrerit. Praesent eget libero sed tortor sollicitudin pretium. Nulla gravida convallis eros hendrerit ultrices. Vestibulum semper velit ac suscipit mollis. Ut eget felis tincidunt, convallis ex vitae, dignissim erat. Phasellus aliquam et urna quis eleifend. Ut sodales, odio blandit dapibus sagittis, ex libero rutrum lectus, quis sollicitudin est mi eget libero. Nulla porttitor lacinia interdum.',
]

const skeletonCss = css`
  /* Taken from BlueprintJS Skeleton */
  @keyframes skeleton-glow {
    from {
      background: #bcbcbc;
      border-color: #bcbcbc;
    }
    to {
      background: #f4f4f4;
      border-color: #f4f4f4;
    }
  }

  /* This class hides content with a glowing, rounded rectangle. CSS properties that should always override consumer values use the "!important" rule. */
  animation: skeleton-glow 1000ms linear infinite alternate;
  /* do not !important this for Firefox support */
  background: #bcbcbc;
  /* Prevent background color from extending to the border and overlappping */
  background-clip: padding-box !important;
  border-color: #bcbcbc !important;
  border-radius: 2px;
  box-shadow: none !important;
  /* Transparent text will occupy space but be invisible to the user */
  color: transparent !important;
  cursor: default;
  pointer-events: none;
  user-select: none;
  /* Make pseudo-elements (CSS icons) and children invisible */
  &::before,
  &::after,
  * {
    visibility: hidden !important;
  }
`

const headerCss = css`
  display: flex;
  justify-content: space-between;
`

interface SkeletonLoaderProps {
  project: NERDProjectMetadata
}

function renderSkeletonMetadataItem(_: null, idx: number): React.ReactNode {
  return (
    <p key={idx} css={skeletonCss}>
      skeleton
    </p>
  )
}

const METADATA_SKELETONS = [
  <MetadataItem
    key="0"
    title={<span css={skeletonCss}>Knowledge Base</span>}
    items={[null, null, null, null, null]}
    itemRenderer={renderSkeletonMetadataItem}
  />,
  <MetadataItem
    key="1"
    title={<span css={skeletonCss}>Knowledge Base</span>}
    items={[null, null, null, null, null]}
    itemRenderer={renderSkeletonMetadataItem}
  />,
]

export default function SkeletonLoader(props: SkeletonLoaderProps): JSX.Element {
  const {project} = props
  return (
    <ResultsLayout additionalMetadata={METADATA_SKELETONS} project={project}>
      <article>
        <header css={headerCss}>
          <div css={skeletonCss} style={{width: 200}}>
            skeleton
          </div>
          <div css={skeletonCss} style={{width: 150}}>
            skeleton
          </div>
        </header>
        {DUMMY_PARAGRAPHS.map((text, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <p key={idx} css={skeletonCss}>
            {text}
          </p>
        ))}
      </article>
    </ResultsLayout>
  )
}
