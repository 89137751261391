import {css} from '@emotion/react'
import {IsHoverTargetContext} from '@kensho/icons'
import {useState} from 'react'

import Popover from './ui/overlays/Popover'

const wrapperCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

const tooltipCss = css`
  background: rgba(0, 0, 0, 0.72);
  color: #fff;
  padding: 3px 8px;
  max-width: 275px;
`

interface TooltipProps {
  children: React.ReactNode
  content: React.ReactNode
}

export default function Tooltip(props: TooltipProps): JSX.Element {
  const {children, content} = props
  const [isOpen, setIsOpen] = useState(false)
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null)

  return (
    <IsHoverTargetContext.Provider value>
      <div
        ref={setTargetElement}
        css={wrapperCss}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        {children}
      </div>
      <Popover hideArrow isOpen={isOpen} targetElement={targetElement} placement="bottom-end">
        {content && <div css={tooltipCss}>{content}</div>}
      </Popover>
    </IsHoverTargetContext.Provider>
  )
}
