import {forwardRef, useContext, useMemo} from 'react'
import {css, SerializedStyles} from '@emotion/react'
import {useElementWidth} from '@kensho/tacklebox'

import {IconComponent, Intent, Size, Theme} from '../types'
import {BORDER, FOCUS_OUTLINE, TEXT_PLACEHOLDER} from '../constants/theme'
import {useTheme} from '../colors/ThemeProvider'
import getTextColor from '../colors/getTextColor'
import {FOCUS_OUTLINE_OFFSET} from '../constants/values'
import assertNever from '../utils/assertNever'
import getBackgroundColor from '../colors/getBackgroundColor'

import DisplayGroupContext, {DisplayGroup} from './DisplayGroupContext'

const HEIGHT: Record<Size, number> = {
  small: 24,
  medium: 30,
  large: 40,
}

const FONT_SIZE: Record<Size, number> = {
  small: 12,
  medium: 14,
  large: 16,
}

const PADDING_X: Record<Size, number> = {
  small: 8,
  medium: 10,
  large: 10,
}

const WIDTH: Record<Size, number> = {
  small: 250,
  medium: 300,
  large: 300,
}

const ICON_SIZE = 20

const ICON_LEFT: Record<Size, number> = {
  small: 8,
  medium: 10,
  large: 14,
}

const ICON_MARGIN: Record<Size, number> = {
  small: 8,
  medium: 8,
  large: 10,
}

function getBorderColor(theme: Theme, intent?: Intent, disabled?: boolean): string {
  switch (intent) {
    case 'primary':
    case 'danger':
      return getBackgroundColor({theme, disabled, intent})
    case undefined:
      return BORDER[theme]
    default:
      return assertNever(intent)
  }
}

function getDisabled(disabled: boolean, context: DisplayGroup | null): boolean {
  if (context === null) {
    return disabled
  }
  switch (context.type) {
    case 'button':
      return disabled
    case 'control':
    case 'input':
    case 'label':
      return context.disabled || disabled
    default:
      return assertNever(context)
  }
}

function getFill(context: DisplayGroup | null, fill: boolean): boolean {
  if (context === null) {
    return fill
  }
  switch (context.type) {
    case 'control':
      return fill || context.fill
    case 'button':
    case 'input':
    case 'label':
      return fill
    default:
      return assertNever(context)
  }
}

function getSize(context: DisplayGroup | null, size?: Size): Size {
  if (context?.type === 'control') {
    return context.size
  }
  if (size) {
    return size
  }
  if (context?.type === 'label') {
    return context.size
  }
  return 'medium'
}

const cssHorizontalBorder = css`
  :not(:last-child) {
    input {
      border-right: none;
    }
  }
`

const cssVerticalBorder = css`
  :not(:last-child) {
    input {
      border-bottom: none;
    }
  }
`

export function getGroupBorderCss(context: DisplayGroup | null): SerializedStyles | null {
  if (context === null) return null
  if (context.type === 'control') {
    return context.vertical ? cssVerticalBorder : cssHorizontalBorder
  }
  return null
}

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  /** Space-separated list of classes to pass to the underlying element. */
  className?: string

  /**
   * Whether to disable interactivity.
   *
   * @default false
   */
  disabled?: boolean

  /**
   * Whether to expand the input to fill available horizontal space.
   *
   * @default false
   */
  fill?: boolean

  /** Visual intent of the input. */
  intent?: Intent

  /** Icon to render to the left of the input value. */
  leftIcon?: IconComponent

  /** Callback to invoke when the input value changes. */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void

  /**
   * Illustrative text to render in the absence of a value.
   */
  placeholder?: string

  /**
   * Whether to prevent the user from entering text.
   *
   * @default false
   */
  readOnly?: boolean

  /** Element to render to the right of the input value. */
  rightElement?: React.ReactNode

  /**
   * The size of the input.
   *
   * @default 'medium'
   */
  size?: Size

  /** Type of the input. */
  type?: 'email' | 'password' | 'search' | 'tel' | 'text' | 'url'

  /** Value of the input. */
  value: string
}

/**
 * Allows the user to input text.
 *
 * Renders as a group with optionally an icon to the left and content to the right.
 * Controls and iconography inside the Input should be _directly related_ to the contents and control of the Input.
 * Controls that are grouped with an Input, but do not directly affect the contents of the Input, should use
 * a ControlGroup instead.
 *
 * @see ControlGroup to group an input with related controls.
 */
function Input(props: InputProps, ref: React.Ref<HTMLInputElement>): JSX.Element {
  const {
    className,
    disabled: propDisabled = false,
    fill: propFill = false,
    intent,
    leftIcon: LeftIcon,
    rightElement,
    size: propSize,
    type = 'text',
    ...rest
  } = props
  const group = useContext(DisplayGroupContext)
  const theme = useTheme()

  const disabled = getDisabled(propDisabled, group)
  const size = getSize(group, propSize)
  const fill = getFill(group, propFill)
  const hasRightElement = rightElement != null
  const [rightElementWidth, rightElementRef] = useElementWidth()
  const paddingRightStyle = {paddingRight: hasRightElement ? rightElementWidth : PADDING_X[size]}
  const cssGroupBorder = getGroupBorderCss(group)

  const cssInputGroup = css`
    cursor: ${disabled ? 'not-allowed' : undefined};
    display: block;
    position: relative;
    width: ${fill ? '100%' : `${WIDTH[size]}px`};
  `

  const cssIcon = css`
    z-index: 1;
    position: absolute;
    top: calc(50% - ${ICON_SIZE / 2}px);
    left: ${ICON_LEFT[size]}px;
    color: ${getTextColor({theme, disabled})};
    pointer-events: none;
  `

  const cssInput = css`
    background: none;
    box-sizing: border-box;
    color: ${getTextColor({theme, disabled})};
    font: inherit;
    position: relative;
    resize: none;
    width: 100%;
    height: ${HEIGHT[size]}px;
    line-height: ${HEIGHT[size]}px;
    font-size: ${FONT_SIZE[size]}px;
    border: ${intent ? 2 : 1}px solid ${getBorderColor(theme, intent, disabled)};
    padding-left: ${LeftIcon ? ICON_SIZE + ICON_LEFT[size] + ICON_MARGIN[size] : PADDING_X[size]}px;

    :disabled {
      cursor: not-allowed;
    }

    outline-offset: ${FOCUS_OUTLINE_OFFSET}px;
    :focus-visible {
      outline: ${FOCUS_OUTLINE[theme]};
    }

    ::placeholder {
      /* TODO: Finalize this color */
      color: ${TEXT_PLACEHOLDER[theme]};
    }

    :disabled::placeholder {
      color: ${getTextColor({theme, disabled})};
    }
  `

  const cssRightElement = css`
    position: absolute;
    top: 0;
    right: 0;
    height: ${HEIGHT[size]}px;
    display: flex;
  `

  const inputGroup = useMemo<DisplayGroup>(
    () => ({type: 'input', disabled, size, height: HEIGHT[size]}),
    [disabled, size]
  )

  return (
    <DisplayGroupContext.Provider value={inputGroup}>
      <div css={[cssInputGroup, cssGroupBorder]} role="group" className={className}>
        {LeftIcon != null && <LeftIcon css={cssIcon} size={ICON_SIZE} />}
        <input
          css={cssInput}
          disabled={disabled}
          ref={ref}
          style={paddingRightStyle}
          type={type}
          {...rest}
        />
        {hasRightElement && (
          <span css={cssRightElement} ref={rightElementRef}>
            {rightElement}
          </span>
        )}
      </div>
    </DisplayGroupContext.Provider>
  )
}

export default forwardRef(Input)
