import {AsyncValue} from '@kensho/tacklebox'
import {useCallback, useContext, useEffect, useMemo, useReducer, useState} from 'react'
import {useParams} from 'react-router-dom'

import fetchResource from '../api/fetchResource'
import UserContext from '../providers/UserContext'
import {NERDAPIResult, NERDProjectMetadata, NERDProjects, NERDAPIResultSuccess} from '../types'
import assertNever from '../utils/assertNever'

import useAsyncInterval from './useAsyncInterval'

type ProjectsDispatchAction =
  | {type: 'setError'; error: Error}
  | {type: 'setProjects'; projects: NERDProjectMetadata[]}
  | {type: 'updateProject'; id: string; project: Partial<NERDProjectMetadata>}
  | {type: 'addProject'; project: NERDProjectMetadata}

function projectsReducer(
  state: AsyncValue<NERDProjectMetadata[]>,
  action: ProjectsDispatchAction
): AsyncValue<NERDProjectMetadata[]> {
  switch (action.type) {
    case 'setError':
      return {status: 'error', error: action.error}
    case 'setProjects':
      return {
        status: 'ready',
        value: [...action.projects].sort(
          (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
        ),
      }
    case 'addProject':
      if (state.status !== 'ready') return state
      return {status: 'ready', value: [action.project, ...state.value]}
    case 'updateProject': {
      if (state.status !== 'ready') return state
      const projectIdx = state.value.findIndex((project) => project.jobId === action.id)
      if (projectIdx === -1) return state

      return {
        status: 'ready',
        value: [
          ...state.value.slice(0, projectIdx),
          {...state.value[projectIdx], ...action.project},
          ...state.value.slice(projectIdx + 1),
        ],
      }
    }
    default:
      return assertNever(action)
  }
}

export interface Projects {
  asyncProjects: AsyncValue<NERDProjectMetadata[]>
  asyncResults: AsyncValue<NERDAPIResultSuccess>
  addProject: (project: NERDProjectMetadata) => void
}

export default function useProjects(): Projects {
  const [asyncProjects, projectsDispatch] = useReducer(projectsReducer, {status: 'pending'})
  const [asyncResults, setAsyncResults] = useState<AsyncValue<NERDAPIResultSuccess>>({
    status: 'pending',
  })
  const {user} = useContext(UserContext)
  const {id} = useParams()

  useEffect(() => {
    if (id != null) setAsyncResults({status: 'pending'})
  }, [id])

  const pollResults = useCallback((): Promise<NERDAPIResult | null> => {
    if (id == null) return Promise.resolve(null)
    return fetchResource<NERDAPIResult>(`/api/private/projects/${id}`, {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    })
  }, [id, user?.token])

  const handleResponse = useCallback(
    (result: NERDAPIResult | null): void => {
      if (id == null || result == null) return

      if (result.status === 'pending') return

      projectsDispatch({type: 'updateProject', id, project: {status: 'success'}})

      if (result.status === 'error') {
        setAsyncResults({status: 'error', error: new Error(result.message)})
      }

      if (result.status === 'success') {
        setAsyncResults({status: 'ready', value: result})
      }
    },
    [id]
  )

  const handleError = useCallback((error: unknown) => {
    const logError = error instanceof Error ? error : new Error('Error fetching project results.')
    setAsyncResults({status: 'error', error: logError})
  }, [])

  useAsyncInterval(
    pollResults,
    id != null && asyncResults.status === 'pending' && user?.token ? 2000 : null,
    handleResponse,
    handleError
  )

  useEffect(() => {
    let isCurrent = true
    const abortController = new AbortController()
    const {signal} = abortController

    const init = {
      signal,
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    }

    fetchResource<NERDProjects>('/api/private/projects', init)
      .then(({results}) => isCurrent && projectsDispatch({type: 'setProjects', projects: results}))
      .catch(
        (error) =>
          isCurrent &&
          projectsDispatch({
            type: 'setError',
            error: error instanceof Error ? error : new Error('Unable to fetch projects.'),
          })
      )

    return () => {
      isCurrent = false
      abortController.abort()
    }
  }, [user?.token])

  return useMemo(() => {
    function addProject(project: NERDProjectMetadata): void {
      projectsDispatch({type: 'addProject', project})
    }

    return {asyncProjects, asyncResults, addProject}
  }, [asyncProjects, asyncResults])
}
