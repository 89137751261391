import {useContext, useState} from 'react'
import {css} from '@emotion/react'
import {Link} from 'react-router-dom'

import UserContext from '../providers/UserContext'
import useLoginURL from '../hooks/useLoginURL'

import Button from './ui/controls/Button'
import Dialog from './ui/overlays/Dialog'
import DialogBody from './ui/overlays/DialogBody'
import Menu from './ui/menu/Menu'
import MenuItem from './ui/menu/MenuItem'
import Popover from './ui/overlays/Popover'

const userInfoCss = css`
  color: white;
  position: relative;
  border-left: 1px solid #2d2d2d;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 6px;
`

const buttonCss = css`
  color: inherit;
`

const menuCss = css`
  border: none;
`

const signInDividerCss = css`
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #999;
  margin: 10px 0;
  &::before,
  &::after {
    content: '';
    flex: 1 1 auto;
    height: 1px;
    background: #eee;
  }
  &::before {
    margin-right: 5px;
  }
  &::after {
    margin-left: 5px;
  }
`

export default function UserInfo(): JSX.Element | null {
  const {user, logout} = useContext(UserContext)
  const loginPaths = useLoginURL()

  const [signInOpen, setSignInOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [popoverTarget, setPopoverTarget] = useState<HTMLElement | null>(null)

  return (
    <div css={userInfoCss}>
      {user?.email ? (
        <>
          <Button css={buttonCss} minimal onClick={() => setMenuOpen(true)} ref={setPopoverTarget}>
            {user.email}
          </Button>
          <Popover
            isOpen={menuOpen}
            targetElement={popoverTarget}
            onClose={() => setMenuOpen(false)}
          >
            <Menu css={menuCss}>
              <Link to="/account">
                <MenuItem text="Account" />
              </Link>
              <MenuItem text="Log Out" onClick={logout} />
            </Menu>
          </Popover>
        </>
      ) : (
        <>
          <Button css={buttonCss} minimal onClick={() => setSignInOpen(true)}>
            Sign in
          </Button>
          <Dialog isOpen={signInOpen} title="Account Sign In" onClose={() => setSignInOpen(false)}>
            <DialogBody>
              <a href={loginPaths.URL}>
                <Button fill intent="primary" size="large">
                  Sign in
                </Button>
              </a>
              <p css={signInDividerCss}>or</p>
              <a href={loginPaths.MARKETPLACE_IDP_URL}>
                <Button fill size="large">
                  Sign in with S&amp;P Marketplace
                </Button>
              </a>
            </DialogBody>
          </Dialog>
        </>
      )}
    </div>
  )
}
