import {css} from '@emotion/react'
import {IconExclamation, IconPlus} from '@kensho/icons'
import {useContext} from 'react'
import {Link} from 'react-router-dom'

import NonIdealState from '../../components/ui/displays/NonIdealState'
import ProjectsContext from '../../providers/ProjectsContext'
import {NERDProjectMetadata} from '../../types'

import NERDResultSuccess from './NERDResultSuccess'
import SkeletonLoader from './SkeletonLoader'

const buttonLinkCss = css`
  display: flex;
  align-items: center;
  background: #09819f;
  height: 30px;
  padding: 0 9px;
  color: #ffffff;
  align-self: flex-start;
  font-size: 12px;
  margin: 20px 0;

  svg {
    margin-right: 6px;
  }

  :hover {
    background: #086e87;
  }
`

const nonIdealStateCss = css`
  margin-top: 24px;

  > svg {
    height: 42px;
    width: 42px;
  }
`

interface NERDResultsProps {
  project: NERDProjectMetadata
}

export default function NERDResults(props: NERDResultsProps): JSX.Element {
  const {project} = props
  const {asyncResults} = useContext(ProjectsContext)

  if (asyncResults.status === 'pending') return <SkeletonLoader project={project} />
  if (asyncResults.status === 'error') {
    return (
      <NonIdealState
        css={nonIdealStateCss}
        icon={IconExclamation}
        title="An error occurred while annotating your text."
        description="Create a new project using the button below."
        action={
          <Link css={buttonLinkCss} to="/projects">
            <IconPlus /> New NERD Project
          </Link>
        }
      />
    )
  }

  return <NERDResultSuccess nerdResults={asyncResults.value} project={project} />
}
