import {css} from '@emotion/react'
import {IconInfo, IconXSmall} from '@kensho/icons'

import {useTheme} from '../colors/ThemeProvider'
import {BACKGROUND, BACKGROUND_L3, BORDER, TEXT_NORMAL} from '../constants/theme'
import Button from '../controls/Button'
import {IconComponent} from '../types'

import Overlay from './Overlay'

const WIDTH = 544

export interface DialogProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Whether the backdrop should blur the background.
   */
  blurBackdrop?: boolean

  /**
   * Whether pressing the escape key should invoke `onClose`.
   *
   * @default true
   */
  canEscapeKeyClose?: boolean

  /**
   * Whether clicking outside the dialog (either on backdrop when present or on document)
   * should invoke `onClose`.
   *
   * @default true
   */
  canOutsideClickClose?: boolean

  /** Contents of the dialog. */
  children?: React.ReactNode

  /** Space-separated list of classes to pass to the underlying element. */
  className?: string

  /**
   * Icon to render in the dialog header. If null, renders no icon.
   *
   * @default IconInfo
   */
  icon?: IconComponent | null

  /**
   * Whether the dialog is displaying its content.
   *
   * @default false
   */
  isOpen?: boolean

  /**
   * A label for the dialog, used for accessibility. If omitted, the dialog uses the `title` prop
   * as the label. Labels are required on dialogs for accessibility, so both cannot be ommitted.
   */
  label?: string

  /** Callback to invoke when the dialog is closed. */
  onClose?: () => void

  /**
   * The element to focus when the dialog opens. If nothing is passed, focuses the first focusable element in the dialog content.
   * This prop can be used to focus the most common action (such as a "Continue" button), or the least destructive action in a process
   * that is not easily reversable (such as a "Cancel" button in a deletion modal).
   *
   * @see https://www.w3.org/TR/wai-aria-practices/#keyboard-interaction-7
   */
  onOpenFocusRef?: React.RefObject<HTMLElement>

  /** Title of the dialog. If omitted, the dialog will not render a header. */
  title?: string
}

/**
 * Renders a dialog window on top of the entire application.
 *
 * @see DialogBody for styling the main content of a Dialog.
 * @see DialogFooter for styling the footer content of a Dialog.
 */
export default function Dialog(props: DialogProps): JSX.Element {
  const {
    blurBackdrop = false,
    canEscapeKeyClose = true,
    canOutsideClickClose = true,
    children,
    className,
    label,
    icon: Icon = IconInfo,
    isOpen = false,
    onClose,
    onOpenFocusRef,
    title,
    ...rest
  } = props
  const theme = useTheme()

  if (!label && !title) {
    throw new Error('Dialogs must be provided with a label or title in order to be accessible!')
  }

  const cssDialog = css`
    position: fixed;
    top: 300px;
    left: 50%;
    width: ${WIDTH}px;
    margin-left: -${WIDTH / 2}px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    background: ${BACKGROUND_L3[theme]};
    color: ${TEXT_NORMAL[theme]};
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  `

  const cssHeaderContainer = css`
    background: ${BACKGROUND[theme]};
    height: 40px;
    border-bottom: 1px solid ${BORDER[theme]};
    display: flex;
    align-items: center;
    padding: 0 20px;
  `

  const cssHeaderIcon = css`
    margin-right: 12px;
  `

  const cssHeader = css`
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    flex: 1;
  `

  return (
    <Overlay
      blurBackdrop={blurBackdrop}
      canEscapeKeyClose={canEscapeKeyClose}
      canOutsideClickClose={canOutsideClickClose}
      isOpen={isOpen}
      onClose={onClose}
      onOpenFocusRef={onOpenFocusRef}
    >
      <div
        className={className}
        css={cssDialog}
        role="dialog"
        aria-modal
        aria-label={label || title}
        {...rest}
      >
        {title != null && (
          <div css={cssHeaderContainer}>
            {Icon && <Icon css={cssHeaderIcon} size={20} />}
            <h4 css={cssHeader}>{title}</h4>
            {onClose != null && (
              <Button
                icon={IconXSmall}
                minimal
                size="small"
                onClick={onClose}
                aria-label="Close Dialog"
              />
            )}
          </div>
        )}
        {children}
      </div>
    </Overlay>
  )
}
