import {css} from '@emotion/react'
import {useCallback, useContext, useState} from 'react'

import {IconComponent, Size} from '../types'
import {useTheme} from '../colors/ThemeProvider'
import getBackgroundColor, {BackgroundColorOptions} from '../colors/getBackgroundColor'
import {FOCUS_OUTLINE, TEXT_NORMAL} from '../constants/theme'
import {FOCUS_OUTLINE_OFFSET} from '../constants/values'

import MenuContext from './MenuContext'
import {useRoverItem} from './Rover'

const PADDING_Y: Record<Size, number> = {
  small: 3,
  medium: 6,
  large: 10,
}

const HEIGHT: Record<Size, number> = {
  small: 24,
  medium: 30,
  large: 40,
}

export interface MenuItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  /** Whether the menu item is active (pressed).
   *
   * @default false
   */
  active?: boolean

  /** Whether to disable interactivity.
   *
   * @default false
   */
  disabled?: boolean

  /** Icon to render to the left of the item's contents. */
  icon?: IconComponent

  /** Whether to allow `text` to wrap across multiple lines. If `false`, `text` is truncated with an ellipsis.
   *
   * @default false
   */
  multiline?: boolean

  /** Callback function that fires on selecting the menu item (through click or otherwise). */
  onSelect?: () => void

  /** Icon to render at the trailing end (right in LTR contexts) of the item's contents. */
  trailingElement?: React.ReactNode

  /** The text to render in this menu item. */
  text: React.ReactNode

  /** The HTML title for the item. Defaults to `text` if `text` is a string. */
  title?: string
}

/**
 * A single interactive item in a Menu.
 */
export default function MenuItem(props: MenuItemProps): JSX.Element {
  const {
    active,
    disabled,
    icon: Icon,
    multiline = false,
    onSelect,
    trailingElement,
    text,
    title,
    ...rest
  } = props
  const theme = useTheme()
  const {size = 'medium'} = useContext(MenuContext)
  const [buttonElement, setButtonElement] = useState<HTMLButtonElement | null>(null)
  const {active: focused, activateItem} = useRoverItem(buttonElement, disabled)

  const handleSelect = useCallback(() => {
    activateItem()
    onSelect?.()
  }, [onSelect, activateItem])

  const colorOptions: BackgroundColorOptions = {
    depth: active ? -2 : 0,
    disabled,
    theme,
  }

  const background = getBackgroundColor(colorOptions)

  const cssMenuItem = css`
    background: ${background};
    padding: ${multiline ? PADDING_Y[size] : 0}px ${Icon ? 7 : 10}px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    font: inherit;
    width: 100%;
    height: ${multiline ? 'auto' : `${HEIGHT[size]}px`};
    text-align: left;
    color: ${TEXT_NORMAL[theme]};
    outline-offset: ${FOCUS_OUTLINE_OFFSET}px;
  `

  const cssInteractive = css`
    :hover {
      background: ${getBackgroundColor({...colorOptions, depth: -1})};
    }

    :active {
      background: ${getBackgroundColor({...colorOptions, depth: -2})};
    }

    :focus-visible {
      outline: ${FOCUS_OUTLINE[theme]};
    }
  `

  const cssDisabled = css`
    cursor: not-allowed;
    opacity: 0.5;
  `

  const cssIcon = css`
    margin-right: 7px;
  `

  const cssText = css`
    flex: 1;
  `
  const cssTruncateText = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `

  const cssTrailingElement = css`
    text-align: right;
    margin-left: 7px;
  `

  return (
    <button
      css={[cssMenuItem, disabled ? cssDisabled : cssInteractive]}
      disabled={disabled}
      onClick={handleSelect}
      title={title || (typeof text === 'string' && text) || undefined}
      type="button"
      ref={setButtonElement}
      role="menuitem"
      tabIndex={focused ? 0 : -1}
      {...rest}
    >
      {Icon && <Icon css={cssIcon} size={20} />}
      <span css={[cssText, !multiline && cssTruncateText]}>{text}</span>
      {trailingElement && <span css={cssTrailingElement}>{trailingElement}</span>}
    </button>
  )
}
