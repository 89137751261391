import {AnnotationGraphItem} from '@kensho/updoc'

import isNERDAnnotation from '../../predicates/isNERDAnnotation'
import isOutOfKnowledgeBaseAnnotation from '../../predicates/isOutOfKnowledgeBaseAnnotation'
import {EntityGroup, KnowledgeBase, NERDAnnotation} from '../../types'

export default function generateMetadataGroups(
  items: AnnotationGraphItem[]
): [EntityGroup, NERDAnnotation[]][] {
  const nerdAnnotations = items.map((item) => item.annotation).filter(isNERDAnnotation)

  const groupKeys = new Set(nerdAnnotations.map((a) => a.metadata.knowledgeBase))
  const knowledgeBaseGroups = Array.from(groupKeys).map<[KnowledgeBase, NERDAnnotation[]]>(
    (group) => [group, []]
  )
  const groups = new Map<EntityGroup, NERDAnnotation[]>([
    ...knowledgeBaseGroups,
    ['OutOfKnowledgeBase', []],
  ])

  nerdAnnotations.forEach((annotation) => {
    if (isOutOfKnowledgeBaseAnnotation(annotation)) {
      const group = groups.get('OutOfKnowledgeBase')
      if (group) groups.set('OutOfKnowledgeBase', [...group, annotation])
    } else {
      const group = groups.get(annotation.metadata.knowledgeBase)
      if (group) groups.set(annotation.metadata.knowledgeBase, [...group, annotation])
    }
  })

  return Array.from(groups)
}
