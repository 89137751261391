import {css} from '@emotion/react'

const spinnerCss = css`
  fill: none;
  stroke: #999;
  stroke-linecap: round;
`

const animateCss = css`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  stroke: #09819f;
  transform-origin: center;
  transition: stroke-dashoffset 500ms;
  animation: spin 1000ms linear infinite;
`

const PATH = 'M 50,50 m 0,-44 a 44,44 0 1 1 0,88 a 44,44 0 1 1 0,-88'

interface SpinnerProps {
  size?: number
}

export default function Spinner(props: SpinnerProps): JSX.Element {
  const {size = 16} = props
  return (
    <svg css={spinnerCss} width={size} height={size} strokeWidth={10} viewBox="0 0 100 100">
      <path d={PATH} />
      <path
        css={animateCss}
        d={PATH}
        pathLength={100}
        strokeDasharray={100}
        strokeDashoffset={75}
      />
    </svg>
  )
}
