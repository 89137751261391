import {css} from '@emotion/react'
import {IconExclamation} from '@kensho/icons'

import Button from '../components/ui/controls/Button'
import NonIdealState from '../components/ui/displays/NonIdealState'
import Dialog from '../components/ui/overlays/Dialog'
import DialogBody from '../components/ui/overlays/DialogBody'
import DialogFooter from '../components/ui/overlays/DialogFooter'
import DialogFooterActions from '../components/ui/overlays/DialogFooterActions'
import {EMAILS} from '../constants'

const nonIdealStateCss = css`
  svg {
    height: 64px;
    width: 64px;
    fill: #db374d;
  }
`

interface ProjectErrorDialogProps {
  isOpen: boolean
  onCancel: () => void
  onRetry: () => void
}

export default function ProjectErrorDialog(props: ProjectErrorDialogProps): JSX.Element {
  const {isOpen, onCancel, onRetry} = props

  return (
    <Dialog isOpen={isOpen} title="NERD Project Creation Error" onClose={onCancel}>
      <DialogBody>
        <NonIdealState
          css={nonIdealStateCss}
          title="Submission Failed"
          description="Project creation failed. Text will not be annotated."
          icon={IconExclamation}
        />

        <p>
          You may <b>retry</b> to upload your text to NERD. If that still doesn&apos;t work, you can
          cancel this project and start again from scratch.
        </p>
        <p>
          If you continue to experience difficulty successfully uploading your text, please reach
          out to <a href={`mailto:${EMAILS.SUPPORT}`}>{EMAILS.SUPPORT}</a> with the subject NERD UI.
        </p>
      </DialogBody>
      <DialogFooter>
        <DialogFooterActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button intent="primary" onClick={onRetry}>
            Resubmit
          </Button>
        </DialogFooterActions>
      </DialogFooter>
    </Dialog>
  )
}
