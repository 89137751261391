import {StrictMode} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'

import AppLoggerProvider from './AppLoggerProvider'
import ThemeProvider from './components/ui/colors/ThemeProvider'
import ToasterProvider from './components/ui/toasts/ToasterProvider'
import UserProvider from './providers/UserProvider'
import GlobalStyles from './GlobalStyles'
import Home from './pages/Home'
import Account from './pages/Account'
import Projects from './pages/Projects'
import ProjectDetails from './projects/ProjectDetails'
import ProjectResults from './projects/ProjectResults'
import ErrorBoundary from './components/ErrorBoundary'

export default function App(): JSX.Element {
  return (
    <StrictMode>
      <AppLoggerProvider>
        <BrowserRouter>
          <ErrorBoundary>
            <ThemeProvider theme="light">
              <ToasterProvider>
                <GlobalStyles />
                <UserProvider>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/projects" element={<Projects />}>
                      <Route index element={<ProjectDetails />} />
                      <Route path=":id" element={<ProjectResults />} />
                    </Route>
                  </Routes>
                </UserProvider>
              </ToasterProvider>
            </ThemeProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </AppLoggerProvider>
    </StrictMode>
  )
}
