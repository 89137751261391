import {Annotation} from '@kensho/updoc'

import isEntityAnnotation from '../../predicates/isEntityAnnotation'
import isOutOfKnowledgeBaseAnnotation from '../../predicates/isOutOfKnowledgeBaseAnnotation'

export default function isAnnotationInteracted(
  annotation: Annotation,
  list: Annotation[]
): boolean {
  return list.some(
    (interacted) =>
      (isEntityAnnotation(interacted) &&
        isEntityAnnotation(annotation) &&
        annotation.metadata.entityKbId === interacted.metadata.entityKbId) ||
      (isOutOfKnowledgeBaseAnnotation(interacted) &&
        isOutOfKnowledgeBaseAnnotation(annotation) &&
        annotation.metadata.text === interacted.metadata.text)
  )
}
