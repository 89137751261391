import {css} from '@emotion/react'
import {IconCopy} from '@kensho/icons'
import {useContext} from 'react'

import SignInOverlay from '../components/SignInOverlay'
import UserContext from '../providers/UserContext'
import copyText from '../utils/copyText'

import Page from './Page'

const mainCss = css`
  max-width: 900px;
  width: 100%;
  padding: 27px 27px 0 27px;
  margin: 0 auto;
`

const h1Css = css`
  font-size: 24px;
  color: rgba(0, 0, 0, 0.87);
`

const labelCss = css`
  display: block;
  font-weight: bold;
  line-height: 20px;
  margin: 0;
  margin-bottom: 10px;
`

const inputCss = css`
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 4px 8px;
  height: 36px;
  width: 370px;
  max-width: 100%;
  margin-top: 4px;
  display: block;
  font-weight: normal;
`

const copyableValueCss = css`
  margin: 4px 0;
`

const copyLabelCss = css`
  font-weight: bold;
  line-height: 20px;
  margin: 0;
`

const valueCss = css`
  color: #545454;
  background: rgba(239, 239, 239, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 4px 8px;
  width: 370px;
  max-width: 100%;
  margin: 4px 0 0 0;
  word-break: break-all;
  max-height: 5em;
  overflow-y: auto;
`

const valueRowCss = css`
  display: flex;
  align-items: center;
`

const iconCopyCss = css`
  margin-left: 12px;
  color: #aaa;
  cursor: pointer;
  transition: all 0.2s;
  padding-top: 5px;

  &:hover {
    color: #000;
  }
`

const descriptionCss = css`
  margin: 4px 0 0 8px;
`

interface CopyableValueProps {
  className?: string
  value: string
  label?: string
  description?: React.ReactNode
}

function CopyableValue(props: CopyableValueProps): JSX.Element {
  const {className, value, label, description} = props

  return (
    <div className={className} css={copyableValueCss}>
      {label && <p css={copyLabelCss}>{label}</p>}
      <div css={valueRowCss}>
        <p css={valueCss}>{value}</p>
        <IconCopy css={iconCopyCss} size={26} onClick={() => copyText(value)} />
      </div>
      {description && <div css={descriptionCss}>{description}</div>}
    </div>
  )
}

export default function Account(): JSX.Element {
  const {user} = useContext(UserContext)
  return (
    <Page title="Account">
      <SignInOverlay />
      <div data-private css={mainCss}>
        <h1 css={h1Css}>Account Details</h1>

        {user && (
          <>
            {user.email && (
              <label css={labelCss} htmlFor="email-input">
                Email Address
                <input id="email-input" css={inputCss} type="text" disabled value={user.email} />
              </label>
            )}
            <CopyableValue
              value={user.refreshToken}
              label="NERD Refresh Token"
              description="Used to generate new temporary Access Tokens"
            />
            {user.token && (
              <CopyableValue
                value={user.token}
                label="NERD Access Token"
                description="Used to programmatically access the API"
              />
            )}
          </>
        )}
      </div>
    </Page>
  )
}
