import {css, ClassNames} from '@emotion/react'
import {useCallback, useMemo, useState} from 'react'
import {Annotation, AnnotationStyle, Segment, useDocument, Viewer} from '@kensho/updoc'
import {useElementSize} from '@kensho/tacklebox'

import {NERDProjectMetadata, NERDAPIResultSuccess, NERDAnnotation, EntityGroup} from '../../types'
import isNERDAnnotation from '../../predicates/isNERDAnnotation'
import isOutOfKnowledgeBaseAnnotation from '../../predicates/isOutOfKnowledgeBaseAnnotation'
import Popover from '../../components/ui/overlays/Popover'

import generateKDMFromNERDResults from './generateKDMFromNERDResults'
import ResultsLayout from './ResultsLayout'
import generateEntityColorMap, {DISABLED_COLOR} from './generateEntityColorMap'
import isAnnotationInteracted from './isAnnotationInteracted'
import getStyle from './getStyle'
import DocumentInteractionHeader from './DocumentInteractionHeader'
import annotationOverlayRenderer from './annotationOverlayRenderer'
import AnnotationPopoverDetail from './AnnotationPopoverDetail'
import generateMetadataGroups from './generateMetadataGroups'
import EntityGroupMetadataItem from './EntityGroupMetadataItem'

const wrapperCss = css`
  position: relative;
  /* TODO: This is brittle. Ensures text appears through any annotation styling. Is this a common pattern? */
  canvas {
    mix-blend-mode: multiply;
  }
`

const hiddenTextCss = css`
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
`

const popoverCss = css`
  background: transparent;
`

const popoverContentCss = css`
  /*
   * We offset the popover content with padding and a negative margin so that there is a visible
   * gap between the content and the annotation, but no gap in the element. This allows us to track
   * the mouse as it moves onto the content to keep it open for the user to interact with.
   */
  padding: 8px;
  margin: -10px;
`

function getPopoverTargetElement(uid: string): HTMLElement | null {
  return document.body.querySelector<HTMLElement>(`[data-annotation-uid="${uid}"]`)
}

interface NERDResultSuccessProps {
  nerdResults: NERDAPIResultSuccess
  project: NERDProjectMetadata
}

export default function NERDResultSuccess(props: NERDResultSuccessProps): JSX.Element {
  const {nerdResults, project} = props
  const [textSize, containerRef] = useElementSize()
  const [selectedAnnotations, setSelectedAnnotations] = useState<NERDAnnotation[]>([])
  const [hoveredAnnotations, setHoveredAnnotations] = useState<NERDAnnotation[]>([])
  const [isColoredByNerType, setIsColoredByNerType] = useState(true)
  const [nedThreshold, setNedThreshold] = useState('0')
  const [disabledEntityGroups, setDisabledEntityGroups] = useState<Set<EntityGroup>>(new Set())

  const nerdKdm = useMemo(() => generateKDMFromNERDResults(nerdResults), [nerdResults])
  const colorMap = useMemo(() => generateEntityColorMap(nerdResults.results), [nerdResults.results])

  const {kdm} = useDocument(nerdKdm)

  const isAnnotationOn = useCallback(
    (annotation: Annotation): boolean => {
      if (!isNERDAnnotation(annotation)) return false

      const isGroupOff = isOutOfKnowledgeBaseAnnotation(annotation)
        ? disabledEntityGroups.has('OutOfKnowledgeBase')
        : disabledEntityGroups.has(annotation.metadata.knowledgeBase)

      if (isGroupOff) return false

      if (nedThreshold !== '0') {
        if (isOutOfKnowledgeBaseAnnotation(annotation)) return false
        return annotation.metadata.nedScore >= Number(nedThreshold)
      }

      return true
    },
    [nedThreshold, disabledEntityGroups]
  )

  const getAnnotationStyle = useCallback(
    (annotation: Annotation): AnnotationStyle => {
      if (!isNERDAnnotation(annotation)) return {}

      // If the annotation is turned off, always show a greyed out highlight regardless of interaction/knowledgebase
      if (!isAnnotationOn(annotation)) return {backgroundColor: DISABLED_COLOR}

      const isHovered = isAnnotationInteracted(annotation, hoveredAnnotations)
      const isSelected = isAnnotationInteracted(annotation, selectedAnnotations)
      const pageHasInteraction = hoveredAnnotations.length !== 0 || selectedAnnotations.length !== 0

      return getStyle(annotation, colorMap, {
        isHovered,
        isSelected,
        isColoredByNerType,
        pageHasInteraction,
      })
    },
    [selectedAnnotations, hoveredAnnotations, isColoredByNerType, colorMap, isAnnotationOn]
  )

  const handleAnnotationsHover = useCallback(
    (annotations: Annotation[]): void =>
      setHoveredAnnotations(annotations.filter(isAnnotationOn).filter(isNERDAnnotation)),
    [isAnnotationOn]
  )

  const handleAnnotationsClick = useCallback(
    (annotations: Annotation[]): void =>
      setSelectedAnnotations(annotations.filter(isAnnotationOn).filter(isNERDAnnotation)),
    [isAnnotationOn]
  )

  const getAnnotationOverlayRenderer = useCallback(
    (annotation: Annotation, segments: Segment[]): React.ReactNode | null => {
      if (!isNERDAnnotation(annotation)) return null
      return annotationOverlayRenderer(annotation, segments)
    },
    []
  )

  const annotationKnowledgeBaseGroups = generateMetadataGroups([...kdm.annotationGraph.annotations])
  const groupMetadata = annotationKnowledgeBaseGroups.map(([group, annotations]) => (
    <EntityGroupMetadataItem
      key={group}
      group={group}
      annotations={annotations}
      isEnabled={!disabledEntityGroups.has(group)}
      getAnnotationStyle={getAnnotationStyle}
      onAnnotationsClick={handleAnnotationsClick}
      onAnnotationsHover={handleAnnotationsHover}
      onGroupDisplayToggle={(groupToToggle) => {
        setDisabledEntityGroups((prev) => {
          const next = new Set(prev)

          if (prev.has(groupToToggle)) {
            next.delete(groupToToggle)
          } else {
            next.add(groupToToggle)
          }

          return next
        })
      }}
    />
  ))

  const popoverTarget =
    selectedAnnotations.length !== 0 ? getPopoverTargetElement(selectedAnnotations[0].uid) : null

  return (
    <ResultsLayout project={project} results={nerdResults} additionalMetadata={groupMetadata}>
      <article>
        <DocumentInteractionHeader
          nedThreshold={nedThreshold}
          setNedThreshold={setNedThreshold}
          isColoredByNerType={isColoredByNerType}
          setIsColoredByNerType={setIsColoredByNerType}
        />
        <div css={wrapperCss}>
          {textSize.height && textSize.width && (
            <Viewer
              document={kdm}
              height={textSize.height}
              width={textSize.width}
              getAnnotationStyle={getAnnotationStyle}
              onAnnotationsClick={handleAnnotationsClick}
              onAnnotationsHover={handleAnnotationsHover}
              getAnnotationOverlayRenderer={getAnnotationOverlayRenderer}
            />
          )}
          {/* TODO: Determine whether there's a better way to get the appropriate height/width of the rendered text */}
          <div ref={containerRef} css={hiddenTextCss}>
            {nerdResults.text}
          </div>
        </div>
      </article>
      <ClassNames>
        {(content) => (
          <Popover
            hideArrow
            css={popoverCss}
            wrapperClassName={content.css({boxShadow: 'none'})}
            isOpen={popoverTarget !== null}
            targetElement={popoverTarget}
            onClose={() => setSelectedAnnotations([])}
          >
            <div css={popoverContentCss}>
              {selectedAnnotations.map((selected) => (
                <AnnotationPopoverDetail
                  key={selected.uid}
                  annotation={selected}
                  headerColor={getAnnotationStyle(selected).backgroundColor}
                />
              ))}
            </div>
          </Popover>
        )}
      </ClassNames>
    </ResultsLayout>
  )
}
