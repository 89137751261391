import {ErrorCode, FileRejection} from 'react-dropzone'

function getFileTypeErrorMessage(fileName: string): string {
  return `Unsupported File Type: Kensho NERD currently only supports text files. Your file, ${fileName}, is an unsupported type.`
}

const UNKNOWN_ERROR = 'Unknown Error: An unexpected error occurred, please try again.'

// TODO: Direct user to API?
const TOO_MANY_FILES_ERROR = 'Too Many Files: This UI only supports annotating one file at a time.'

export default function getDropzoneErrorMessage(rejectedFiles: FileRejection[]): string {
  const rejectedFile = rejectedFiles[0]
  const isTooManyFiles = rejectedFile.errors.some((error) => error.code === ErrorCode.TooManyFiles)
  const isUnsupportedType = rejectedFile.errors.some(
    (error) => error.code === ErrorCode.FileInvalidType
  )
  let errorMessage = UNKNOWN_ERROR
  if (isTooManyFiles) errorMessage = TOO_MANY_FILES_ERROR
  if (isUnsupportedType) errorMessage = getFileTypeErrorMessage(rejectedFile.file.name)
  return errorMessage
}
