import {colord} from 'colord'

import {DEPTH_MIX_RATIO} from '../constants/values'

import {Depth} from './DepthContext'

/**
 * Applies depth to a provided color by lightening or darkening the color based on a provided depth.
 *
 * @param color The color to apply depth to.
 * @param depth The depth level to use.
 * @returns The resulting darkened or lightened color.
 */
export default function applyDepth(color: string, depth: Depth): string {
  const rgba = colord(color).toRgb()
  // Use RGBA space instead of HSLA space because colord rounds when converting between the two.
  // The following code is equivalent to simply multiplying the luminosity by the given ratio.
  return colord({
    r: rgba.r * (1 + DEPTH_MIX_RATIO * depth),
    g: rgba.g * (1 + DEPTH_MIX_RATIO * depth),
    b: rgba.b * (1 + DEPTH_MIX_RATIO * depth),
    a: rgba.a,
  }).toHex()
}
