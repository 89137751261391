import {css} from '@emotion/react'
import {useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import fetchResource from '../api/fetchResource'
import Spinner from '../components/Spinner'
import Button from '../components/ui/controls/Button'
import Checkbox from '../components/ui/controls/Checkbox'
import ControlGroup from '../components/ui/controls/ControlGroup'
import ProjectsContext from '../providers/ProjectsContext'
import UserContext from '../providers/UserContext'
import isNonNullable from '../utils/isNonNullable'

import {TAG_PEOPLE_ENTITLEMENT} from './constants'
import EditableText from './EditableText'
import FileUpload from './FileUpload'
import Project from './Project'
import ProjectErrorDialog from './ProjectErrorDialog'

const contentCss = css`
  display: flex;
  flex-direction: column;
  margin: 0 54px;
`

const textToAnnotateCss = css`
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 480px;

  textarea {
    resize: none;
    height: 100%;
    &:active,
    &:focus {
      outline-color: #09819f;
    }
  }
`

const knowledgebaseControlCss = css`
  span {
    font-size: 16px;
  }

  label + label {
    margin-left: 24px;
  }
`

const selectionErrorCss = css`
  color: #992736;
  font-size: 12px;
  height: 18px;
`

const submitButtonCss = css`
  align-self: flex-end;
  min-width: 165px;
`

const fillInputCss = css`
  width: 100%;
`

const inputWrapperCss = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

// The NERD BE will attempt to encode the project name into the job_id so that it can be
// pulled out for the projects list endpoint without a round-trip to S3 for each project.
// It will truncate the encoded name to 18 characters so this matches that behavior.
const MAX_PROJECT_SHORTNAME_SIZE = 18

type InputFocus = 'none' | 'textarea' | 'filepicker'

function canSubmitText(focus: InputFocus, text: string, file: File | null): boolean {
  if (focus === 'none') return false
  if (focus === 'textarea') return text.trim() !== ''
  if (focus === 'filepicker') return file !== null
  return false
}

function getTextToSubmit(
  focus: 'textarea' | 'filepicker',
  textareaContent: string,
  file: File | null
): Promise<string> {
  if (focus === 'textarea') return Promise.resolve(textareaContent)
  if (file === null) return Promise.reject(new Error('No file'))
  return file.text()
}

function getSelectedKnowledgeBases(isCapIQChecked: boolean, isWikimediaChecked: boolean): string[] {
  return [isCapIQChecked ? 'capiq' : null, isWikimediaChecked ? 'wikimedia' : null].filter(
    isNonNullable
  )
}

export default function ProjectDetails(): JSX.Element {
  const [isCapIQChecked, setIsCapIQChecked] = useState(true)
  const [isWikimediaChecked, setIsWikimediaChecked] = useState(true)
  const [inputFocus, setInputFocus] = useState<InputFocus>('none')
  const [textareaContent, setTextareaContent] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const [projectName, setProjectName] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasError, setHasError] = useState(false)
  const {user} = useContext(UserContext)
  const {addProject} = useContext(ProjectsContext)
  const navigate = useNavigate()

  const hasSelectionError = !isCapIQChecked && !isWikimediaChecked
  const canSubmit =
    !hasSelectionError && canSubmitText(inputFocus, textareaContent, file) && !isSubmitting

  function handleSubmit(): void {
    if (inputFocus === 'none') return

    setHasError(false)
    setIsSubmitting(true)
    getTextToSubmit(inputFocus, textareaContent, file)
      .then((text) => {
        const canTagPeople = user?.entitlements?.includes(TAG_PEOPLE_ENTITLEMENT)
        const init = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user?.token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            text,
            request_id: projectName || 'Untitled Project',
            knowledge_bases: getSelectedKnowledgeBases(isCapIQChecked, isWikimediaChecked),
            tag_people: canTagPeople && isCapIQChecked,
          }),
        }
        return fetchResource<{jobId: string; requestId: string}>('/api/v1/annotations-async', init)
      })
      .then(({jobId, requestId}) => {
        const requestIdPartial =
          requestId.length > MAX_PROJECT_SHORTNAME_SIZE
            ? `${requestId.slice(0, MAX_PROJECT_SHORTNAME_SIZE)}…`
            : requestId

        // Match the BE date format of "yyyy-mm-dd hh:mm:ss" in UTC
        const [date, time] = new Date().toISOString().split('T')
        const createdAt = `${date} ${time.split('.')[0]}`

        addProject({
          jobId,
          requestIdPartial,
          createdAt,
          status: 'pending',
        })

        navigate(`/projects/${jobId}`)
      })
      .catch(() => {
        setHasError(true)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <>
      <Project title="Project Details">
        <article css={contentCss}>
          <header>
            <EditableText
              value={projectName}
              onChange={(event) => setProjectName(event.target.value)}
              placeholder="Untitled Project"
            />
          </header>

          <section css={inputWrapperCss}>
            {inputFocus !== 'filepicker' && (
              <label css={[textToAnnotateCss, inputFocus === 'textarea' && fillInputCss]}>
                Paste (or type) text for Kensho NERD to annotate:
                <textarea
                  onChange={(event) => setTextareaContent(event.target.value)}
                  onFocus={() => setInputFocus('textarea')}
                  onBlur={() => {
                    if (textareaContent === '') setInputFocus('none')
                  }}
                />
              </label>
            )}

            {inputFocus !== 'textarea' && (
              <FileUpload
                file={file}
                onDragEnter={() => setInputFocus('filepicker')}
                onDragLeave={() => setInputFocus('none')}
                onFileClear={() => {
                  setFile(null)
                  setInputFocus('none')
                }}
                onFileAccept={(acceptedFile) => {
                  setInputFocus('filepicker')
                  setFile(acceptedFile)
                }}
              />
            )}
          </section>

          <p>
            NERD annotates to both CapIQ and Wikimedia entities by default. Select only one option
            to use that specific model.
          </p>
          <ControlGroup css={knowledgebaseControlCss}>
            <Checkbox
              label="CapIQ"
              checked={isCapIQChecked}
              onChange={(event) => setIsCapIQChecked(event.target.checked)}
            />
            <Checkbox
              label="Wikimedia"
              checked={isWikimediaChecked}
              onChange={(event) => setIsWikimediaChecked(event.target.checked)}
            />
          </ControlGroup>

          <p css={selectionErrorCss}>
            {hasSelectionError
              ? 'At least one selection is required. If you are unsure, select both.'
              : ''}
          </p>

          <Button
            css={submitButtonCss}
            intent="primary"
            disabled={!canSubmit}
            onClick={handleSubmit}
          >
            {isSubmitting ? <Spinner /> : 'Submit for annotation'}
          </Button>
        </article>
      </Project>
      <ProjectErrorDialog
        isOpen={hasError}
        onCancel={() => setHasError(false)}
        onRetry={handleSubmit}
      />
    </>
  )
}
