import {useStructuredReducer} from '@kensho/tacklebox'

import uniqueId from '../utils/uniqueId'

import {Toast, Toaster, ToastOptions, ToastKey} from './types'

type ToastAction =
  | {type: 'show'; key: ToastKey; options: ToastOptions}
  | {type: 'dismiss'; key: ToastKey}
  | {type: 'clear'}

function assertNever(action: never): never {
  throw new Error(`Unexpected action: ${action}`)
}

function reducer(state: Toast[], action: ToastAction): Toast[] {
  switch (action.type) {
    case 'show': {
      const {key, options} = action
      const toastOptions = typeof options === 'string' ? {message: options} : options
      return [...state, {...toastOptions, key}]
    }
    case 'dismiss':
      return state.filter((toast) => toast.key !== action.key)
    case 'clear':
      return []
    default:
      return assertNever(action)
  }
}

function createToaster(dispatch: React.Dispatch<ToastAction>): Toaster {
  return {
    show(options) {
      const key = uniqueId('toast')
      dispatch({type: 'show', key, options})
      return key
    },
    dismiss(key) {
      dispatch({type: 'dismiss', key})
    },
    clear() {
      dispatch({type: 'clear'})
    },
  }
}

export default function useToasterState(): [Toast[], Toaster] {
  return useStructuredReducer(createToaster, reducer, [])
}
