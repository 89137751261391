import {css} from '@emotion/react'
import {IconDocumentNew} from '@kensho/icons'
import {useCallback} from 'react'
import {FileRejection, useDropzone} from 'react-dropzone'

import Button from '../components/ui/controls/Button'
import useToaster from '../components/ui/toasts/useToaster'

import getDropzoneErrorMessage from './getDropzoneErrorMessage'

const dropzoneCss = css`
  width: 420px;
  height: 480px;
  background: #f2f8fa;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  cursor: pointer;
`

const instructionCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0;
    margin-bottom: 15px;
  }
`

const orCss = css`
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
`

const clickCss = css`
  color: #1a819a;
  font-weight: 700;
`

const fillInputCss = css`
  width: 100%;
`

const fileChosenCss = css`
  display: flex;
  align-items: center;

  button {
    color: #09819f;
  }

  h3 {
    font-weight: normal;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.54);
    margin-right: 24px;
  }
`

interface FileUploadProps {
  file: File | null
  onFileAccept: (file: File) => void
  onFileClear: () => void
  onDragEnter: () => void
  onDragLeave: () => void
}

export default function FileUpload(props: FileUploadProps): JSX.Element {
  const {file, onDragEnter, onDragLeave, onFileAccept, onFileClear} = props
  const toaster = useToaster()
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length !== 0) {
        const message = getDropzoneErrorMessage(rejectedFiles)
        toaster.show({message, intent: 'danger'})
        onFileClear()
      }
      if (acceptedFiles.length === 1) {
        onFileAccept(acceptedFiles[0])
      }
    },
    [onFileAccept, onFileClear, toaster]
  )

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    /*
     * TODO: There is a Chrome bug that incorrectly allows .csv files to be chosen when a MIME type of .txt is passed
     * @see https://bugs.chromium.org/p/chromium/issues/detail?id=1353740
     */
    accept: {'text/plain': ['.txt']},
    maxFiles: 1,
    multiple: false,
    onDrop,
    onDragEnter,
    onDragLeave,
  })

  return file === null ? (
    <div css={[dropzoneCss, isDragActive && fillInputCss]} {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <>
          <IconDocumentNew size={48} />
          <p>Drop file</p>
        </>
      ) : (
        <div css={instructionCss}>
          <p>Drag &amp; Drop a plain text (.txt) file</p>
          <p css={orCss}>Or</p>
          <p css={clickCss}>Click to Upload a File</p>
        </div>
      )}
    </div>
  ) : (
    <div css={fileChosenCss}>
      <h3>{file.name}</h3>
      <Button minimal onClick={onFileClear}>
        Change
      </Button>
    </div>
  )
}
