import {IconExclamation, IconPlus} from '@kensho/icons'
import {css} from '@emotion/react'
import {Link} from 'react-router-dom'

import {DATE_FORMATTER, TIME_FORMATTER, PROJECT_LIFESPAN_MS} from './constants'

const projectCss = css`
  background: #f8f8f8;
  height: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
`

const headerCss = css`
  height: 50px;
  background: #f1f2f3;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.25);
  padding: 12px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  h2 {
    margin: 0;
    font-size: 20px;
  }
`

const contentCss = css`
  max-width: 960px;
  margin: 16px auto 0;
  background: #ffffff;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.02);
  padding: 30px 0;
  flex: 1;
`

const buttonLinkCss = css`
  display: flex;
  align-items: center;
  background: #09819f;
  height: 30px;
  padding: 0 9px;
  color: #ffffff;
  align-self: flex-start;
  font-size: 14px;

  svg {
    margin-right: 6px;
  }

  :hover {
    background: #086e87;
  }
`

const scrollContainerCss = css`
  overflow: auto;
`

const creationTimeHeaderCss = css`
  margin: 16px auto 0;
  font-size: 12px;
  line-height: 18px;
  max-width: 960px;
  text-align: right;
`

const expiryWarningCss = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #c61936;

  svg {
    margin-right: 4px;
    fill: #c61936;
  }
`

interface ProjectProps {
  children: React.ReactNode
  title: string
  showNewProjectButton?: boolean
  creationTime?: string
}

export default function Project(props: ProjectProps): JSX.Element {
  const {children, title, showNewProjectButton = false, creationTime} = props

  // The creationTime we get receive does not contain TZ info but is UTC so we add in the TZ
  const creationDate = creationTime ? Date.parse(`${creationTime}Z`) : null

  return (
    <article css={projectCss}>
      <header css={headerCss}>
        <h2>{title}</h2>
        {showNewProjectButton && (
          <Link css={buttonLinkCss} to="/projects">
            <IconPlus /> New NERD Project
          </Link>
        )}
      </header>
      {/* Wrap the content in a scrollable container to ensure scrollbar appears at the edge of the browser window */}
      <section css={scrollContainerCss}>
        {creationDate && (
          <header css={creationTimeHeaderCss}>
            <span>
              <b>Project Created:</b> {DATE_FORMATTER.format(creationDate)} at{' '}
              {TIME_FORMATTER.format(creationDate)}
            </span>
            <span css={expiryWarningCss}>
              <IconExclamation /> This project will expire after{' '}
              {DATE_FORMATTER.format(creationDate + PROJECT_LIFESPAN_MS)}. All data will be deleted.
            </span>
          </header>
        )}
        <article css={contentCss}>{children}</article>
      </section>
    </article>
  )
}
