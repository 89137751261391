import {css} from '@emotion/react'
import {IconAbout, IconChevronRight, IconCirclePlay, IconFolder, IconLock} from '@kensho/icons'

import {URLS} from '../constants'
import nerdLogoImg from '../assets/nerd-logo.svg'
import nerdResultsImg from '../assets/nerd-results.png'

import GraphicElement from './GraphicElement'
import Link from './Link'

const landingCss = css`
  h1,
  h2,
  h3 {
    font-weight: 700;
    margin: 0;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
  }

  p {
    font-size: 16px;
  }
`

const contentPositioningCss = css`
  max-width: 1020px;
  margin: 0 auto;

  @media screen and (max-width: 1024px) {
    margin: 0 12px;
  }
`

const contentCss = css`
  padding: 60px 0;
`

const infoCss = css`
  padding-top: 42px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 16px;
  grid-column-gap: 16px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  section,
  header {
    max-width: 435px;
    grid-column: 1;
  }
`

const infoButtonsCss = css`
  display: flex;

  a + a {
    margin-left: 24px;
  }
`

const quickstartCss = css`
  background: #f5fafc;
`

const quickstartTilesCss = css`
  display: flex;
  justify-content: space-between;
  margin-top: 42px;
  text-align: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const buttonLinkCss = css`
  display: flex;
  align-items: center;
  background: #09819f;
  padding: 10px 15px;
  color: #ffffff;
  align-self: flex-start;

  :hover {
    background: #086e87;
  }
`

const minimalButtonLinkCss = css`
  background: transparent;
  color: #05718c;

  :hover {
    background: rgba(19, 158, 191, 0.15);
  }
`

const trialCss = css`
  display: flex;
  flex-direction: column;
`

const wordmarkCss = css`
  margin-bottom: 24px;

  @media screen and (max-width: 600px) {
    margin-bottom: 12px;
  }
`

const nerdResultsImgWrapperCss = css`
  grid-column: 2;
  grid-row: 1 / 3;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    grid-column: 1;
    grid-row: 2;
  }
`

const nerdResultsImgCss = css`
  width: 100%;
`

export default function Landing(): JSX.Element {
  return (
    <article css={landingCss}>
      <article css={[contentCss, contentPositioningCss, infoCss]}>
        <header>
          <img css={wordmarkCss} src={nerdLogoImg} alt="Kensho NERD" />
          <h1>Understand all organizations, people, places and things in text.</h1>
        </header>
        <section>
          <h2>
            Identify entities in documents and text without writing a single line of code with NERD
            UI.
          </h2>
          <p>
            NERD UI will identify entities in documents and text, then connect them to data and
            information in S&amp;P Capital IQ or Wikimedia. NERD recognizes nearly 100 million
            entities.
          </p>
          <div css={infoButtonsCss}>
            <Link css={buttonLinkCss} to="/projects">
              Use NERD UI <IconChevronRight />
            </Link>
            <Link css={[buttonLinkCss, minimalButtonLinkCss]} to="/docs/guides/">
              Use NERD API
            </Link>
          </div>
        </section>
        <div css={nerdResultsImgWrapperCss}>
          <img src={nerdResultsImg} css={nerdResultsImgCss} alt="Kensho NERD Results" />
        </div>
      </article>
      <article css={[contentCss, quickstartCss]}>
        <section css={contentPositioningCss}>
          <h2>API Quickstart Guides &amp; NERD FAQs</h2>
          <p>Guides to help quickly setup authentication and API access</p>
          <section css={quickstartTilesCss}>
            <GraphicElement
              icon={<IconLock size={36} />}
              heading="API Authentication Quickstart"
              description="Generate a JWT token OR keypair to authenticate to the NERD API"
              link={{to: '/docs/guides/authentication-quickstart/'}}
              label="Get Started"
            />
            <GraphicElement
              icon={<IconFolder size={36} />}
              heading="NERD Rest API Quickstart"
              description="Annotate text documents using the NERD API including API reference"
              link={{to: '/docs/guides/text_annotation/'}}
              label="Get Started"
            />
            <GraphicElement
              icon={<IconAbout size={36} />}
              heading="NERD UI &amp; API FAQs"
              description="Check the NERD FAQs for answers to the most common questions"
              link={{to: '/docs/faq/'}}
              label="Read FAQs"
            />
            <GraphicElement
              icon={<IconCirclePlay size={36} />}
              heading="Video Guides"
              description="Watch our video guides on how to use NERD"
              link={{to: 'https://vimeo.com/showcase/10292715'}}
              label="Get Started"
            />
          </section>
        </section>
      </article>
      <article css={contentCss}>
        <section css={[contentPositioningCss, trialCss]}>
          <h2>Try NERD for Free!</h2>
          <p>
            Test NERD&apos;s capabilities on your files with 500,000 free characters (about 500
            pages).
          </p>
          <a css={buttonLinkCss} href={URLS.FREE_TRIAL}>
            Start a Free Trial
          </a>
        </section>
      </article>
    </article>
  )
}
