import {css, Global} from '@emotion/react'
import proximaNovaRegular from '@kensho/fonts/fonts/proxima-nova-regular.woff2'
import proximaNovaBold from '@kensho/fonts/fonts/proxima-nova-bold.woff2'

const fontsCss = css`
  @font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Proxima Nova'), local('proximanova-regular'),
      url(${proximaNovaRegular}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Proxima Nova Bold'), local('proximanova-bold'),
      url(${proximaNovaBold}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
`

const globalCss = css`
  html {
    font-family: 'Proxima Nova', sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    font-family: 'Proxima Nova', sans-serif;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  button,
  input,
  textarea,
  select {
    font: inherit;
  }

  a {
    text-decoration: none;
    color: #24a9ca;
  }
`

export default function GlobalStyles(): JSX.Element {
  return <Global styles={[fontsCss, globalCss]} />
}
