import {createContext} from 'react'

import {Size} from '../types'

export interface MenuContextValue {
  size?: Size
}

export const DEFAULT_MENU_CONTEXT: MenuContextValue = {}

export default createContext<MenuContextValue>(DEFAULT_MENU_CONTEXT)
