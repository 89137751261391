import {css} from '@emotion/react'

import isEntityAnnotation from '../../predicates/isEntityAnnotation'
import {EntityAnnotation, KnowledgeBase, NERDAnnotation} from '../../types'

import {KNOWLEDGE_BASE_DISPLAY_MAP} from './constants'

const detailCss = css`
  min-width: 300px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px rgb(0 0 0 / 14%),
    0px 1px 18px rgb(0 0 0 / 12%);

  header {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      margin-left: 18px;
      font-weight: normal;
    }
  }

  section {
    background: #fff;
  }

  header,
  section {
    padding: 12px 18px;
  }
  p {
    margin: 6px 0;
  }
  :not(:first-of-type) {
    margin-top: 8px;
  }
`

function formatScore(score: number): number {
  return Math.round(score * 100) / 100
}

// TODO: This should be reworked if more annotation types have different URLs
const URL_GETTER: Record<KnowledgeBase, (id: string, nerType?: string) => string> = {
  wikimedia: (id) => `https://www.wikidata.org/wiki/Q${id}`,
  capiq: (id, nerType) =>
    nerType != null && nerType === 'PERSON'
      ? `https://www.capitaliq.com/CIQDotNet/Person/PersonProfile.aspx?personId=${id}`
      : `https://www.capitaliq.com/CIQDotNet/company.aspx?companyId=${id}`,
}

function buildEntityLink(annotation: EntityAnnotation): string {
  const {entityKbId, nerType, knowledgeBase} = annotation.metadata
  return URL_GETTER[knowledgeBase](entityKbId, nerType)
}

interface AnnotationPopoverDetailProps {
  annotation: NERDAnnotation
  headerColor?: string
}

export default function AnnotationPopoverDetail(props: AnnotationPopoverDetailProps): JSX.Element {
  const {annotation, headerColor} = props
  const title = isEntityAnnotation(annotation)
    ? annotation.metadata.entityLabel
    : annotation.metadata.text

  return (
    <article css={detailCss}>
      <header style={{backgroundColor: headerColor}}>
        <p>{title}</p>
        <span>{KNOWLEDGE_BASE_DISPLAY_MAP[annotation.metadata.knowledgeBase]}</span>
      </header>
      <section>
        {isEntityAnnotation(annotation) && (
          <p>
            <b>Entity ID </b>
            <a href={buildEntityLink(annotation)} target="_blank" rel="noreferrer">
              {annotation.metadata.entityKbId} (click to view entity)
            </a>
          </p>
        )}
        <p>
          <b>Entity Type </b>
          {annotation.metadata.nerType}
        </p>
        {isEntityAnnotation(annotation) && (
          <>
            <p>
              <b>NER Score </b>
              {formatScore(annotation.metadata.nerScore)}
            </p>
            <p>
              <b>NED Score </b>
              {formatScore(annotation.metadata.nedScore)}
            </p>
          </>
        )}
      </section>
    </article>
  )
}
