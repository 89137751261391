import {css} from '@emotion/react'
import {useContext} from 'react'

import {HEADER_HEIGHT, URLS} from '../constants'
import useLoginURL from '../hooks/useLoginURL'
import UserContext from '../providers/UserContext'

import Dialog from './ui/overlays/Dialog'

interface SignInOverlayProps {
  isOpen?: boolean
  onClose?: () => void
}

const modalCss = css`
  position: relative;
  top: ${HEADER_HEIGHT}px;
  left: 0;
  width: 100%;
  margin: 0;
  background: white;
  max-height: 70vh;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
`

const buttonCss = css`
  display: flex;
  align-items: center;
  background: #09819f;
  height: 30px;
  padding: 0 9px;
  color: #ffffff;
  align-self: flex-start;

  :hover {
    background: #086e87;
  }
`

const contentCss = css`
  padding: 50px 25px;
  width: 1250px;
  max-width: 100vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  p {
    margin: 18px 0;
  }
`

const signInCss = css`
  text-decoration: none;
  font-weight: bold;
  transition: all 0.1s ease;

  &:hover {
    opacity: 0.7;
  }
`

const hrCss = css`
  margin: 0;
  border-bottom: 1px solid #000;
`

export default function SignInOverlay(props: SignInOverlayProps): JSX.Element {
  const {isOpen, onClose} = props
  const {user} = useContext(UserContext)
  const loginPaths = useLoginURL()

  return (
    <Dialog
      label="Sign In"
      css={modalCss}
      isOpen={isOpen !== undefined ? isOpen : !user}
      onClose={onClose}
    >
      <section css={contentCss}>
        <p>
          If you already have an account, please{' '}
          <a css={signInCss} href={loginPaths.URL}>
            sign in
          </a>{' '}
          to access the NERD UI. Or,{' '}
          <a css={signInCss} href={loginPaths.MARKETPLACE_IDP_URL}>
            sign in with S&amp;P Marketplace
          </a>
          .
        </p>
        <hr css={hrCss} />
        <p>
          Don&rsquo;t have a Kensho account? Test NERD&apos;s capabilities on your files with
          500,000 free characters (about 500 pages).
        </p>
        <a css={buttonCss} href={URLS.FREE_TRIAL}>
          Start a Free Trial!
        </a>
      </section>
    </Dialog>
  )
}
