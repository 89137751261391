export const BODY_TEXT_PX = 13
export const TEXT_SCALE_RATIO = 1.2
export const LINE_HEIGHT_RATIO = 1.2
export const DEFAULT_BORDER_RADIUS_PX = 4

export const NORMAL_TEXT_OPACITY = 0.95
export const DISABLED_TEXT_OPACITY = 0.35
export const DISABLED_TEXT_WITH_INTENT_OPACITY = 0.5

export const DEPTH_MIX_RATIO = 0.15
export const DEPTH_OPACITY = 0.075
export const DEPTH_OPACITY_WITH_INTENT = 0.125

export const FOCUS_OUTLINE_OFFSET = 2
