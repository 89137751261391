import {NERDAPIResultSuccess} from '../../types'

let annotationUidCounter = 1
function getUid(): string {
  annotationUidCounter += 1
  return annotationUidCounter.toString()
}

export default function generateKDMFromNERDResults(
  nerdResults: NERDAPIResultSuccess
): Record<string, unknown> {
  const nerdAnnotations = nerdResults.results.flatMap(({knowledgeBase, annotations}) =>
    annotations.map((annotation) => ({...annotation, knowledgeBase}))
  )

  const kdmAnnotations = nerdAnnotations.map((nerdAnnotation) => ({
    uid: getUid(),
    type: nerdAnnotation.entityKbId == null ? 'OutOfKnowledgeBaseAnnotation' : 'EntityAnnotation',
    locations: [
      {
        type: 'IndexSpanLocation',
        parentId: '0',
        start: nerdAnnotation.startIndex,
        end: nerdAnnotation.endIndex,
      },
    ],
    metadata: {...nerdAnnotation},
  }))

  return {
    version: '0.3',
    data: {
      contents: [
        {
          uid: '0',
          location: null,
          // TODO: Why doesn't `content` work here?
          source: URL.createObjectURL(new Blob([nerdResults.text], {type: 'text/plain'})),
          __typename: 'TextContent',
        },
      ],
      annotations: kdmAnnotations,
    },
  }
}
