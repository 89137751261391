import {useContext} from 'react'

import {Toaster} from './types'
import ToasterContext from './ToasterContext'

/**
 * A hook to access a Toaster, allowing consumers to programmatically generate Toasts, delete
 * indiviudal Toasts by ID, or clear all Toasts.
 *
 * @returns Toaster, an interface to create and manage Toasts rendered in a ToasterProvider.
 */
export default function useToaster(): Toaster {
  const toaster = useContext(ToasterContext)
  if (!toaster) throw new Error('You can only access a toaster inside of a `<ToasterProvider>`.')
  return toaster
}
