import {css} from '@emotion/react'

export interface DialogBodyProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  className?: string
}

const cssBody = css`
  padding: 20px;
`

/**
 * Styles the main content of a Dialog.
 *
 * @see Dialog for where to use this component.
 */
export default function DialogBody(props: DialogBodyProps): JSX.Element {
  const {children, ...rest} = props
  return (
    <div {...rest} css={cssBody}>
      {children}
    </div>
  )
}
