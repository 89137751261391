import {useTransition, config, animated} from '@react-spring/web'
import {css} from '@emotion/react'

import Overlay from '../overlays/Overlay'

import ToasterContext from './ToasterContext'
import useToasterState from './useToasterState'
import Toast from './Toast'

type Position = 'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-left' | 'bottom-right'

const alignItems: Record<Position, string> = {
  top: 'center',
  'top-left': 'flex-start',
  'top-right': 'flex-end',
  bottom: 'center',
  'bottom-left': 'flex-start',
  'bottom-right': 'flex-end',
}

export interface ToasterProviderProps {
  children?: React.ReactNode
  /** Space-separated list of classes to pass to the underlying element. */
  className?: string
  /** Where to render the container on the screen. */
  position?: Position
}

/**
 * Container to group and display toast messages, rendered on top of the entire application.
 *
 * Calls to `useToaster` must be from components wrapped within a ToasterProvider.
 *
 * @see Toast for the style of messages to be displayed.
 * @see useToaster to create toast messages programmatically within a component.
 */
export default function ToasterProvider(props: ToasterProviderProps): JSX.Element {
  const {children, className, position = 'top'} = props

  const [toasts, toaster] = useToasterState()
  const transition = useTransition(toasts, {
    keys: (item) => item.key,
    config: config.stiff,
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
  })

  const isTop = ['top', 'top-left', 'top-right'].includes(position)
  const isBottom = ['bottom', 'bottom-left', 'bottom-right'].includes(position)
  const cssToastContainer = css`
    align-items: ${alignItems[position]};
    display: flex;
    flex-direction: column;
    z-index: 8;
    top: ${isTop ? 0 : 'auto'};
    bottom: ${isBottom ? 0 : 'auto'};
    padding: 16px;
  `

  const cssToast = css`
    margin-top: ${isTop ? 8 : 0}px;
    margin-bottom: ${isTop ? 0 : 8}px;
  `

  return (
    <>
      <Overlay
        css={cssToastContainer}
        className={className}
        canOutsideClickClose={false}
        isOpen
        hasBackdrop={false}
        enforceFocus={false}
        preventScroll={false}
      >
        {transition((style, item) => (
          <animated.div style={style}>
            <Toast {...item} css={cssToast} onDismiss={() => toaster.dismiss(item.key)} />
          </animated.div>
        ))}
      </Overlay>
      <ToasterContext.Provider value={toaster}>{children}</ToasterContext.Provider>
    </>
  )
}
