export const DATE_FORMATTER = Intl.DateTimeFormat('en-US', {day: 'numeric', month: 'numeric'})

export const TIME_FORMATTER = Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: 'numeric',
  timeZone: (() => {
    const {timeZone} = Intl.DateTimeFormat().resolvedOptions()
    // Guard against some browsers not being able to tell
    if (!timeZone || timeZone === 'Etc/Unknown') {
      return 'UTC'
    }
    return timeZone
  })(),
})

export const PROJECT_LIFESPAN_MS = 1000 * 60 * 60 * 24 * 7 // 7 Days in MS

export const TAG_PEOPLE_ENTITLEMENT = 'people'
