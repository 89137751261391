import {css} from '@emotion/react'

export interface DialogFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  className?: string
}

const cssFooter = css`
  padding: 20px;
`

/**
 * Styles the footer content of a Dialog.
 *
 * @see DialogFooterActions to style action buttons inside a footer.
 * @see Dialog for where to use this component.
 */
export default function DialogFooter(props: DialogFooterProps): JSX.Element {
  const {children, ...rest} = props
  return (
    <div {...rest} css={cssFooter}>
      {children}
    </div>
  )
}
