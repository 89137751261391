import {IconExclamation, IconPlus} from '@kensho/icons'
import {useContext} from 'react'
import {Link, useParams} from 'react-router-dom'
import {css} from '@emotion/react'

import Spinner from '../components/Spinner'
import NonIdealState from '../components/ui/displays/NonIdealState'
import ProjectsContext from '../providers/ProjectsContext'

import Project from './Project'
import NERDResults from './results/NERDResults'

const nonIdealStateCss = css`
  margin-top: 24px;

  > svg {
    height: 42px;
    width: 42px;
  }
`

const buttonLinkCss = css`
  display: flex;
  align-items: center;
  background: #09819f;
  height: 30px;
  padding: 0 9px;
  color: #ffffff;
  align-self: flex-start;
  font-size: 12px;
  margin: 20px 0;

  svg {
    margin-right: 6px;
  }

  :hover {
    background: #086e87;
  }
`

export default function ProjectResults(): JSX.Element {
  const {id} = useParams<{id: string}>()
  const {asyncProjects} = useContext(ProjectsContext)

  if (asyncProjects.status === 'error') {
    return (
      <NonIdealState
        css={nonIdealStateCss}
        icon={IconExclamation}
        title="An error occurred when fetching your projects."
        description="Please refresh to try again."
      />
    )
  }

  if (asyncProjects.status === 'pending') {
    return <NonIdealState css={nonIdealStateCss} title={<Spinner size={30} />} />
  }

  const project = asyncProjects.value.find((p) => p.jobId === id)

  if (!project) {
    return (
      <NonIdealState
        css={nonIdealStateCss}
        icon={IconExclamation}
        title="Project not found."
        description="Choose a project from the sidebar to the left, or create a new project using the button below."
        action={
          <Link css={buttonLinkCss} to="/projects">
            <IconPlus /> New NERD Project
          </Link>
        }
      />
    )
  }

  return (
    <Project title="Project Results" showNewProjectButton creationTime={project.createdAt}>
      <NERDResults project={project} />
    </Project>
  )
}
