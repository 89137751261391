import {KenshoLogomark} from '@kensho/branding'
import {css} from '@emotion/react'
import {IconChevronRight} from '@kensho/icons'
import {Link} from 'react-router-dom'

import {URLS, HEADER_HEIGHT, EMAILS} from '../constants'
import UserInfo from '../components/UserInfo'
import ErrorBoundary from '../components/ErrorBoundary'

const FOOTER_HEIGHT = 122

const headerCss = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${HEADER_HEIGHT}px;
  background: #303333;
  color: rgba(255, 255, 255, 0.95);
  box-shadow: inset 0px -1px 0px #191c1d;
  padding: 0 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  section {
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      color: inherit;
    }
  }
`

const breadcrumbCss = css`
  display: flex;
  align-items: center;
`

const hideOnMobileCss = css`
  @media screen and (max-width: 600px) {
    display: none;
  }
`

const breadcrumbSpacerCss = css`
  margin: 0 6px;
`

const mainCss = css`
  margin: ${HEADER_HEIGHT}px 0 0;
  overflow: hidden;
`

const footerCss = css`
  background: #303333;
  box-shadow: inset 0px -1px 0px #191c1d;
  width: 100%;
  height: ${FOOTER_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const footerContentCss = css`
  display: flex;
  width: 100%;
  max-width: 960px;
  justify-content: space-between;
  color: #ffffff;

  @media screen and (max-width: 1024px) {
    width: auto;
    margin: 10px 12px;
  }

  @media screen and (max-width: 600px) {
    display: block;
  }

  section {
    @media screen and (max-width: 600px) {
      text-align: center;
    }

    p {
      margin: 0;
    }
  }
`

const dividedLinksCss = css`
  display: flex;

  a + a {
    border-left: 1px solid #ffffff;
    padding-left: 5px;
    margin-left: 5px;
  }
`

const navCss = css`
  height: ${HEADER_HEIGHT}px;
`

const navListCss = css`
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
  align-items: center;
  list-style-type: none;
`

const navListItemCss = css`
  display: flex;
  align-items: center;
  position: relative;
  color: rgba(255, 255, 255, 0.87);
  outline: none;
  text-decoration: none;
  margin: 0 6px;
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.1s linear;
  padding: 0 6px;

  a {
    height: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    left: 6px;
    bottom: 0;
    height: 3px;
    width: calc(100% - 12px);
  }

  &.active,
  &.active:hover {
    font-weight: bold;
    color: #90dbec;
    &:after {
      background-color: #90dbec;
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
    color: white;
    &:after {
      background-color: rgba(255, 255, 255, 0.54);
    }
  }
`

const tryApiLinkCss = css`
  color: #00b9e8;
  &:hover {
    color: #00b9e8;
  }
`

interface PageProps {
  children: React.ReactNode
  title?: string
  hideFooter?: boolean
  showTryApiLink?: boolean
  fillViewport?: boolean
}

export default function Page(props: PageProps): JSX.Element {
  const {children, title, hideFooter = false, showTryApiLink = false, fillViewport = false} = props

  const breadcrumbSpacer = <IconChevronRight css={breadcrumbSpacerCss} size={30} />

  const contentHeightCss = css`
    min-height: calc(100vh - ${HEADER_HEIGHT + (hideFooter ? 0 : FOOTER_HEIGHT)}px);
  `

  const fillViewportCss = css`
    height: calc(100vh - ${HEADER_HEIGHT + (hideFooter ? 0 : FOOTER_HEIGHT)}px);
  `

  return (
    <>
      <header css={headerCss}>
        <section>
          <a href={URLS.HOME}>
            <KenshoLogomark size={24} />
          </a>
          <div css={[breadcrumbCss, hideOnMobileCss]}>
            {breadcrumbSpacer}
            <Link to="/">Kensho NERD</Link>
            {title && (
              <>
                {breadcrumbSpacer}
                <p>{title}</p>
              </>
            )}
          </div>
        </section>
        <section>
          <nav css={navCss}>
            <ul css={navListCss}>
              {showTryApiLink && (
                <li css={[navListItemCss, tryApiLinkCss]}>
                  <a href="/docs/guides/">Try NERD API</a>
                </li>
              )}
              <li css={navListItemCss}>
                <a href={`mailto:${EMAILS.SUPPORT}`}>Support</a>
              </li>
            </ul>
          </nav>
          <UserInfo />
        </section>
      </header>
      <main css={[mainCss, contentHeightCss, fillViewport && fillViewportCss]}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
      {!hideFooter && (
        <footer css={footerCss}>
          <div css={footerContentCss}>
            <section>
              <p>
                Have questions? <a href={`mailto:${EMAILS.SUPPORT}`}>Contact the NERD Team</a>
              </p>
            </section>
            <section>
              <p css={dividedLinksCss}>
                <a href={URLS.TERMS_OF_USE}>Web Terms</a>
                <a href={URLS.TERMS_OF_SERVICE}>Service Terms</a>
                <a href={URLS.PRIVACY_POLICY}>Privacy</a>
              </p>
            </section>
          </div>
        </footer>
      )}
    </>
  )
}
