import {css} from '@emotion/react'
import {AnnotationStyleGetter} from '@kensho/updoc'
import {groupBy} from 'lodash'

import Checkbox from '../../components/ui/controls/Checkbox'
import isOutOfKnowledgeBaseAnnotation from '../../predicates/isOutOfKnowledgeBaseAnnotation'
import {EntityGroup, NERDAnnotation} from '../../types'

import {KNOWLEDGE_BASE_DISPLAY_MAP} from './constants'
import MetadataItem from './MetadataItem'

const metadataEntityCss = css`
  margin: 6px;
  font-size: 14px;

  span {
    cursor: default;
  }
`

const entityMetadataTitleCss = css`
  display: flex;
  align-items: center;

  /* Pull the checkbox over to the left into the margin */
  label {
    margin-left: -22px;
  }
`

function renderAnnotationMetadataItem(
  item: {
    title: string
    count: number
    color?: string
  },
  onHover: (leaving: boolean) => void,
  onClick: () => void
): React.ReactNode {
  return (
    <p css={metadataEntityCss} key={item.title}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <span
        onClick={onClick}
        onMouseEnter={() => onHover(false)}
        // TODO: If the popover overlaps the hover interaction this will get triggered right away
        onMouseLeave={() => onHover(true)}
        style={{backgroundColor: item.color}}
      >
        {item.title} ({item.count})
      </span>
    </p>
  )
}

interface EntityGroupMetadataItemProps {
  group: EntityGroup
  annotations: NERDAnnotation[]
  isEnabled: boolean
  getAnnotationStyle: AnnotationStyleGetter
  onGroupDisplayToggle: (group: EntityGroup) => void
  onAnnotationsHover: (annotations: NERDAnnotation[]) => void
  onAnnotationsClick: (annotations: NERDAnnotation[]) => void
}

export default function EntityGroupMetadataItem(props: EntityGroupMetadataItemProps): JSX.Element {
  const {
    group,
    annotations,
    isEnabled,
    getAnnotationStyle,
    onGroupDisplayToggle,
    onAnnotationsClick,
    onAnnotationsHover,
  } = props
  const groupTitle =
    group === 'OutOfKnowledgeBase' ? 'Out of Knowledge Base' : KNOWLEDGE_BASE_DISPLAY_MAP[group]
  const groupedAnnotations = groupBy(annotations, (annotation) =>
    isOutOfKnowledgeBaseAnnotation(annotation)
      ? annotation.metadata.text
      : annotation.metadata.entityKbId
  )

  const entitiesWithCount = Object.values(groupedAnnotations).map((annotationGroup) => {
    const title = isOutOfKnowledgeBaseAnnotation(annotationGroup[0])
      ? annotationGroup[0].metadata.text
      : annotationGroup[0].metadata.entityLabel

    return {
      title,
      count: annotationGroup.length,
      color: getAnnotationStyle(annotationGroup[0]).backgroundColor,
      annotation: annotationGroup[0],
    }
  })

  return (
    <MetadataItem
      isCollapsable
      defaultOpen={group !== 'OutOfKnowledgeBase'}
      items={[...entitiesWithCount].sort((a, b) => b.count - a.count)}
      title={
        <div css={entityMetadataTitleCss}>
          <Checkbox inline checked={isEnabled} onChange={() => onGroupDisplayToggle(group)} />
          {groupTitle}
        </div>
      }
      itemRenderer={(item) =>
        renderAnnotationMetadataItem(
          item,
          (isLeaving: boolean) => onAnnotationsHover(isLeaving ? [] : [item.annotation]),
          () => onAnnotationsClick([item.annotation])
        )
      }
    />
  )
}
