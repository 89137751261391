import {useContext} from 'react'

import SignInOverlay from '../components/SignInOverlay'
import UserProjects from '../projects/UserProjects'
import UserContext from '../providers/UserContext'

import Page from './Page'

export default function Projects(): JSX.Element {
  const {user} = useContext(UserContext)
  return (
    <Page hideFooter showTryApiLink fillViewport>
      <SignInOverlay />
      {!!user && <UserProjects />}
    </Page>
  )
}
