import {RectSegment, Segment} from '@kensho/updoc'

import {NERDAnnotation} from '../../types'

function isRectSegment(segment: Segment): segment is RectSegment {
  return segment.type === 'rect'
}

export default function annotationOverlayRenderer(
  annotation: NERDAnnotation,
  segments: Segment[]
): React.ReactNode | null {
  const firstRectSegment = segments.find(isRectSegment)
  if (!firstRectSegment) return null

  const {x, y, width, height} = firstRectSegment
  return (
    <div
      data-annotation-uid={annotation.uid}
      style={{position: 'absolute', top: y, left: x, width, height}}
    />
  )
}
