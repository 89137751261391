import {css} from '@emotion/react'
import {Placement} from '@popperjs/core'
import {useCallback, useState} from 'react'
import {usePopper} from 'react-popper'

import {useTheme} from '../colors/ThemeProvider'
import {BACKGROUND, BOX_SHADOW, TEXT_NORMAL} from '../constants/theme'

import Overlay from './Overlay'

const ARROW_SIZE = 10

export interface PopoverProps {
  /**
   * The content of the Popover.
   */
  children: React.ReactNode

  /**
   * Space-separated list of classes to pass to the underlying element.
   */
  className?: string

  /**
   * Space-separated list of classes to pass to the wrapping element.
   */
  wrapperClassName?: string

  /**
   * Whether the Popover should prevent focus from leaving itself.
   *
   * @default true
   */
  enforceFocus?: boolean

  /**
   * Whether to apply the `dialog` role to this popover. Should be `false` when using tooltips, and generally `true` otherwise.
   *
   * @default true
   */
  hasDialogRole?: boolean

  /**
   * Whether the Popover is displaying its content.
   *
   * @default false
   */
  isOpen?: boolean

  /**
   * Label to describe the popover for screen readers.
   */
  label?: string

  /**
   * Callback to invoke when the Popover is closed.
   */
  onClose?: () => void

  /**
   * The element to focus when the popover opens. If nothing is passed, focuses the first focusable element in the popover content.
   * This prop can be used to focus the most common action (such as a "Continue" button), or the least destructive action in a process
   * that is not easily reversable (such as a "Cancel" button in a deletion modal).
   *
   * @see https://www.w3.org/TR/wai-aria-practices/#keyboard-interaction-7
   */
  onOpenFocusRef?: React.RefObject<HTMLElement>

  /**
   * The placement of the Popover content relative to its target.
   */
  placement?: Placement

  /**
   * The target element that the Popover content renders near.
   */
  targetElement: HTMLElement | null

  hideArrow?: boolean
}

function isNonNullable<T>(x: T): x is NonNullable<T> {
  return !!x
}

/**
 * Renders overlaid content near a target.
 */
export default function Popover(props: PopoverProps): JSX.Element {
  const {
    children,
    className,
    wrapperClassName,
    enforceFocus = true,
    hasDialogRole = true,
    isOpen = false,
    label,
    onClose,
    onOpenFocusRef,
    placement,
    targetElement,
    hideArrow = false,
  } = props
  const theme = useTheme()
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null)
  const {styles, attributes} = usePopper(targetElement, popperElement, {
    modifiers: [
      {name: 'offset', options: {offset: [0, 8]}},
      !hideArrow ? {name: 'arrow', options: {element: arrowElement}} : null,
    ].filter(isNonNullable),
    placement,
  })

  const onCloseHandler = useCallback(() => {
    targetElement?.focus()
    onClose?.()
  }, [onClose, targetElement])

  const cssPopover = css`
    box-shadow: ${BOX_SHADOW[theme]};
    z-index: 2;
  `

  const cssPopoverContent = css`
    background: ${BACKGROUND[theme]};
    border-radius: 3px;
    color: ${TEXT_NORMAL[theme]};
  `

  const cssArrow = css`
    visibility: hidden;
    position: absolute;
    display: block;
    width: ${ARROW_SIZE}px;
    height: ${ARROW_SIZE}px;
    z-index: -1;

    ::before {
      visibility: visible;
      content: '';
      position: absolute;
      display: block;
      z-index: -1;
      width: ${ARROW_SIZE}px;
      height: ${ARROW_SIZE}px;
      background: ${BACKGROUND[theme]};
      box-shadow: 0px 0px 0px rgba(16, 22, 26, 0.1), 0px 2px 4px rgba(16, 22, 26, 0.2),
        0px 8px 24px rgba(16, 22, 26, 0.2);
      border-radius: 2px;
      transform: rotate(45deg);
    }

    [data-popper-placement^='top'] > & {
      bottom: -${ARROW_SIZE / 2}px;
    }

    [data-popper-placement^='bottom'] > & {
      top: -${ARROW_SIZE / 2}px;
    }

    [data-popper-placement^='left'] > & {
      right: -${ARROW_SIZE / 2}px;
    }

    [data-popper-placement^='right'] > & {
      left: -${ARROW_SIZE / 2}px;
    }
  `

  return (
    <Overlay
      canOutsideClickClose
      enforceFocus={enforceFocus}
      hasBackdrop={false}
      isOpen={isOpen}
      onClose={onCloseHandler}
      onOpenFocusRef={onOpenFocusRef}
      preventScroll={false}
    >
      <div
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        css={cssPopover}
        className={wrapperClassName}
      >
        {!hideArrow && (
          <div ref={setArrowElement} style={styles.arrow} css={cssArrow} aria-hidden />
        )}
        <div
          css={cssPopoverContent}
          className={className}
          role={hasDialogRole ? 'dialog' : undefined}
          aria-label={label}
        >
          {children}
        </div>
      </div>
    </Overlay>
  )
}
