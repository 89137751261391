import {useRef, useLayoutEffect} from 'react'
import {css} from '@emotion/react'

import {Size} from '../types'
import {useTheme} from '../colors/ThemeProvider'
import {BORDER, FOCUS_OUTLINE} from '../constants/theme'
import getBackgroundColor, {BackgroundColorOptions} from '../colors/getBackgroundColor'
import {FOCUS_OUTLINE_OFFSET} from '../constants/values'
import getTextColor from '../colors/getTextColor'

type CheckboxSize = Extract<Size, 'medium' | 'large'>

const SIZE: Record<CheckboxSize, number> = {
  medium: 16,
  large: 20,
}

const FONT_SIZE: Record<CheckboxSize, number> = {
  medium: 14,
  large: 16,
}

const LINE_HEIGHT: Record<CheckboxSize, number> = {
  medium: 18,
  large: 20,
}

const LABEL_PADDING: Record<CheckboxSize, number> = {
  medium: 6,
  large: 10,
}

export interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  /**
   * Whether the checkbox is checked.
   *
   * @default false
   */
  checked?: boolean

  /**
   * Space-separated list of classes to pass to the underlying element.
   */
  className?: string

  /**
   * Whether to disable interactivity.
   *
   * @default false
   */
  disabled?: boolean

  /**
   * Whether the checkbox is indeterminate.
   *
   * @default false
   */
  indeterminate?: boolean

  /**
   *
   * Whether to render the checkbox inline with respect to adjacent elements.
   *
   * @default false
   */
  inline?: boolean

  /** A text label describing the checkbox. */
  label?: React.ReactNode

  /**
   * Callback to invoke when the checked status changes.
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void

  /**
   * The size of the checkbox.
   *
   * @default 'medium'
   */
  size?: CheckboxSize
}

/**
 * Allows the user to toggle between (typically) two states. Can present a third indeterminate state as well.
 *
 * @see Switch for an alternative visual presentation.
 */
export default function Checkbox(props: CheckboxProps): JSX.Element {
  const {
    className,
    disabled = false,
    inline = false,
    indeterminate = false,
    label,
    size = 'medium',
    ...rest
  } = props

  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement>(null)

  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate
    }
  }, [indeterminate])

  const large = size === 'large'

  const colorOptions: BackgroundColorOptions = {
    depth: 0,
    disabled,
    theme,
  }

  const checkColor = getTextColor({disabled, theme, intent: 'primary'})

  const cssCheckboxContainer = css`
    display: ${inline ? 'inline-flex' : 'flex'};
    position: relative;
    align-items: center;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};

    &:hover > input:enabled {
      + span {
        background: ${getBackgroundColor({...colorOptions, depth: -1})};
      }

      &:checked + span,
      &:indeterminate + span {
        background: ${getBackgroundColor({...colorOptions, depth: -1, intent: 'primary'})};
      }
    }

    &:active > input:enabled {
      + span {
        background: ${getBackgroundColor({...colorOptions, depth: -2})};
      }

      &:checked + span,
      &:indeterminate + span {
        background: ${getBackgroundColor({...colorOptions, depth: -2, intent: 'primary'})};
      }
    }
  `

  const cssCheckbox = css`
    /* Hide the default checkbox */
    opacity: 0;
    width: 0;
    margin: 0;

    /* Remove default browser checkbox styling */
    /* See https://developer.mozilla.org/en-US/docs/Learn/Forms/Advanced_form_styling */
    appearance: none;

    &:checked + span,
    &:indeterminate + span {
      /* Show the check when checkbox is in a checked state */
      border: 1px solid transparent;
      background: ${getBackgroundColor({...colorOptions, intent: 'primary'})};
      &::before {
        content: '';
        border-color: ${checkColor};
      }
    }

    /* The dash for indeterminate, will eventually be replaced by icon */
    &:indeterminate + span::before {
      border-left: none;
      transform: none;
      width: ${large ? 10 : 8}px;
      left: ${large ? 5 : 4}px;
      top: 4px;
    }

    &:focus-visible + span {
      outline: ${FOCUS_OUTLINE[theme]};
    }
  `

  const cssReplacementCheckbox = css`
    /* The box */
    display: inline-block;
    width: ${SIZE[size]}px;
    height: ${SIZE[size]}px;
    background: ${getBackgroundColor(colorOptions)};
    border: 1px solid ${BORDER[theme]};
    box-sizing: border-box;
    outline-offset: ${FOCUS_OUTLINE_OFFSET}px;

    /* The check mark, will eventually be replaced by icon */
    ::before {
      box-sizing: border-box;
      position: absolute;
      left: ${large ? 3 : 2}px;
      top: ${large ? 5 : 4}px;
      content: none;
      display: inline-block;
      height: ${large ? 7 : 6}px;
      width: ${large ? 14 : 12}px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
    }
  `

  const cssLabel = css`
    padding-left: ${LABEL_PADDING[size]}px;
    display: inline-block;
    font-size: ${FONT_SIZE[size]}px;
    line-height: ${LINE_HEIGHT[size]}px;
    color: ${getTextColor({disabled, theme})};
  `

  return (
    <label css={cssCheckboxContainer} className={className}>
      <input {...rest} css={cssCheckbox} disabled={disabled} ref={inputRef} type="checkbox" />
      <span css={cssReplacementCheckbox} aria-hidden="true" />
      <span css={cssLabel}>{label}</span>
    </label>
  )
}
