import {css} from '@emotion/react'
import {IconAbout} from '@kensho/icons'

import Tooltip from '../../components/Tooltip'
import Checkbox from '../../components/ui/controls/Checkbox'

const TOOLTIP_CONTENT =
  'NERD predicts which entities are in text. By default, we show all NERD predictions. If you find NERD is making too many annotations, increase this slider to get fewer, higher quality results.'

const controlsCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  label {
    display: flex;
    align-items: center;
  }

  input[type='range'] {
    accent-color: #09819f;
  }
`

interface DocumentInteractionHeaderProps {
  nedThreshold: string
  setNedThreshold: React.Dispatch<React.SetStateAction<string>>
  isColoredByNerType: boolean
  setIsColoredByNerType: React.Dispatch<React.SetStateAction<boolean>>
}

export default function DocumentInteractionHeader(
  props: DocumentInteractionHeaderProps
): JSX.Element {
  const {nedThreshold, setNedThreshold, isColoredByNerType, setIsColoredByNerType} = props
  return (
    <header css={controlsCss}>
      <label>
        Model Sensitivity:
        <Tooltip content={TOOLTIP_CONTENT}>
          <IconAbout css={{margin: '0 5px'}} />
        </Tooltip>
        <input
          type="range"
          min="0"
          step="0.05"
          max="1"
          value={nedThreshold}
          onChange={(event) => setNedThreshold(event.target.value)}
        />
      </label>
      <Checkbox
        label="Color by entity type"
        checked={isColoredByNerType}
        onChange={(event) => setIsColoredByNerType(event.target.checked)}
      />
    </header>
  )
}
