import {css} from '@emotion/react'

import {KnowledgeBase, NERDProjectMetadata, NERDAPIResultSuccess} from '../../types'

import MetadataItem from './MetadataItem'

const resultsHeaderCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 54px;

  /* Allow the header to always be visible when the results require scrolling */
  position: sticky;
  top: 0;
  background: #ffffff;
  margin-top: -24px;
  z-index: 1;

  h1 {
    font-size: 24px;
    margin: 0;
  }
`

const layoutCss = css`
  display: flex;
  margin: 0 54px;
`

const sidebarCss = css`
  width: 225px;
  flex-shrink: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.16);
  padding-right: 12px;
`

const contentCss = css`
  margin-left: 12px;
  font-size: 14px;
  line-height: 18px;
  white-space: pre-line;
  flex: 1;
`

const nerdMetadataCss = css`
  margin: 6px 0;
  font-size: 14px;
`

const KNOWLEDGE_BASE_DISPLAY_MAP: Record<KnowledgeBase, string> = {
  capiq: 'CapIQ',
  wikimedia: 'Wikimedia',
}

function getNERDMetadata(results?: NERDAPIResultSuccess): {key: string; value: string}[] {
  if (results) {
    const modelDisplayNames = results.results
      .map((kbResults) => kbResults.knowledgeBase)
      .map((name) => KNOWLEDGE_BASE_DISPLAY_MAP[name])

    return [
      {key: 'Entities', value: results.entityCount.toString()},
      {key: 'Characters', value: results.characterCount.toString()},
      {key: 'Model', value: modelDisplayNames.join(' & ')},
    ]
  }
  return []
}

function renderNERDMetadataItem(item: {key: string; value: string | number}): React.ReactNode {
  return (
    <p key={item.key} css={nerdMetadataCss}>
      <b>{item.key}</b> {item.value.toString()}
    </p>
  )
}

interface ResultsLayoutProps {
  children: React.ReactNode
  additionalMetadata?: React.ReactNode
  project: NERDProjectMetadata
  results?: NERDAPIResultSuccess
}

export default function ResultsLayout(props: ResultsLayoutProps): JSX.Element {
  const {children, additionalMetadata, project, results} = props

  const nerdMetadata = getNERDMetadata(results)
  const projectName = results ? results.projectName : project.requestIdPartial

  return (
    <article>
      <header css={resultsHeaderCss}>
        <h1>{projectName}</h1>
      </header>
      <section css={layoutCss}>
        <aside css={sidebarCss}>
          {nerdMetadata.length > 0 && (
            <MetadataItem
              title="NERD Metadata"
              items={nerdMetadata}
              itemRenderer={renderNERDMetadataItem}
            />
          )}
          {additionalMetadata}
        </aside>
        <section css={contentCss}>{children}</section>
      </section>
    </article>
  )
}
