import {css} from '@emotion/react'
import {IconExclamation} from '@kensho/icons'
import {NavLink} from 'react-router-dom'

import {NERDProjectMetadata} from '../types'

import {PROJECT_LIFESPAN_MS, DATE_FORMATTER} from './constants'
import ProjectStatus from './ProjectStatus'

const EXPIRY_MESSAGE_THRESHOLD_MS = 1000 * 60 * 60 * 24 * 3 // 3 days
const expiryCss = css`
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`
interface ProjectNavLinkProps {
  project: NERDProjectMetadata
}

export default function ProjectNavLink(props: ProjectNavLinkProps): JSX.Element {
  const {project} = props
  const expireDate = Date.parse(project.createdAt) + PROJECT_LIFESPAN_MS
  const isExpiringSoon = expireDate - EXPIRY_MESSAGE_THRESHOLD_MS < Date.now()
  return (
    <NavLink to={project.jobId} className={({isActive}) => (isActive ? 'active' : '')}>
      <li>
        {project.requestIdPartial}
        <ProjectStatus status={isExpiringSoon ? 'expiring' : project.status}>
          {isExpiringSoon ? (
            <span css={expiryCss}>
              <IconExclamation size={12} /> This project will expire after{' '}
              {DATE_FORMATTER.format(expireDate)}.
            </span>
          ) : null}
        </ProjectStatus>
      </li>
    </NavLink>
  )
}
