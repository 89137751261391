import {Theme} from '../types'

import {
  DISABLED_TEXT_OPACITY,
  DISABLED_TEXT_WITH_INTENT_OPACITY,
  NORMAL_TEXT_OPACITY,
} from './values'

type ThemeVariable = Record<Theme, string>

export const BACKGROUND: ThemeVariable = {
  dark: '#161818',
  light: '#FFFFFF',
}
export const TEXT_NORMAL: ThemeVariable = {
  dark: `rgba(255, 255, 255, ${NORMAL_TEXT_OPACITY})`,
  light: `rgba(0, 0, 0, ${NORMAL_TEXT_OPACITY})`,
}
export const TEXT_DISABLED: ThemeVariable = {
  dark: `rgba(0, 0, 0, ${DISABLED_TEXT_OPACITY})`,
  light: `rgba(0, 0, 0, ${DISABLED_TEXT_OPACITY})`,
}
export const TEXT_INVERTED: ThemeVariable = {
  dark: `#000000`,
  light: '#FFFFFF',
}
export const TEXT_INVERTED_DISABLED: ThemeVariable = {
  dark: `rgba(255, 255, 255, ${DISABLED_TEXT_WITH_INTENT_OPACITY})`,
  light: `rgba(255, 255, 255, ${DISABLED_TEXT_WITH_INTENT_OPACITY})`,
}
export const TEXT_PLACEHOLDER: ThemeVariable = {
  dark: `rgba(255, 255, 255, 0.54)`,
  light: `rgba(0, 0, 0, 0.54)`,
}
export const FOCUS_OUTLINE: ThemeVariable = {
  dark: '2px solid rgba(9, 129, 159, 0.5)',
  light: '2px solid rgba(9, 129, 159, 0.5)',
}

// TODO: the following colors need dark versions
export const PRIMARY: ThemeVariable = {
  dark: '#09819F',
  light: '#09819F',
}

export const DANGER: ThemeVariable = {
  dark: '#DB374D',
  light: '#DB374D',
}

export const BORDER: ThemeVariable = {
  dark: 'rgba(255, 255, 255, 0.5)',
  light: 'rgba(0, 0, 0, 0.16)',
}

export const WHITE: ThemeVariable = {
  dark: '#FFFFFF',
  light: '#FFFFFF',
}

export const BASE_BACKGROUND: ThemeVariable = {
  dark: '#FFFFFF',
  light: '#000000',
}

export const BACKGROUND_L3: ThemeVariable = {
  dark: '#1a1d1d',
  light: '#F8F8F8',
}

// TODO: Need better dark theme box shadow
export const BOX_SHADOW: ThemeVariable = {
  dark: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
  light:
    '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
}
