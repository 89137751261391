import {css} from '@emotion/react'
import {useMemo} from 'react'

import getBackgroundColor from '../colors/getBackgroundColor'
import {useTheme} from '../colors/ThemeProvider'
import {BORDER} from '../constants/theme'
import {Size} from '../types'

import Rover, {useRoverInitialization} from './Rover'
import MenuContext, {MenuContextValue} from './MenuContext'

const WIDTH: Record<Size, number> = {
  small: 132,
  medium: 165,
  large: 220,
}

const FONT_SIZE: Record<Size, number> = {
  small: 14,
  medium: 14,
  large: 16,
}

const LINE_HEIGHT: Record<Size, number> = {
  small: 18,
  medium: 18,
  large: 20,
}

export interface MenuProps extends React.HTMLAttributes<HTMLUListElement> {
  /** Space-separated list of classes to pass to the underlying element. */
  className?: string

  /**
   * The size of the menu.
   *
   * @default 'medium'
   */
  size?: Size
}

/**
 * A list of interactive items.
 *
 * Acts as a container for MenuItems and MenuDividers.
 *
 * Supports full keyboard navigation and a roving tabindex for focus management.
 *
 * @see MenuItem to render interactive items in a Menu.
 * @see MenuDivider to separate content inside a Menu.
 * @see Rover for more information on roving tabindices.
 */
export default function Menu(props: MenuProps): JSX.Element {
  const {children, className, size = 'medium', ...rest} = props
  const theme = useTheme()
  const {activateNext, activatePrevious, ...roverProps} = useRoverInitialization()

  const keyDownHandler = (e: React.KeyboardEvent): void => {
    switch (e.key) {
      case 'ArrowUp':
      case 'ArrowLeft': {
        e.preventDefault()
        activatePrevious()?.element.focus()
        break
      }
      case 'ArrowDown':
      case 'ArrowRight': {
        e.preventDefault()
        activateNext()?.element.focus()
        break
      }
      default:
        break
    }
  }

  const cssMenu = css`
    display: inline-block;
    background: ${getBackgroundColor({theme})};
    padding: 5px;
    margin: 0;
    width: ${WIDTH[size]}px;
    border: 1px solid ${BORDER[theme]};

    font-size: ${FONT_SIZE[size]}px;
    line-height: ${LINE_HEIGHT[size]}px;
  `

  const menu = useMemo<MenuContextValue>(() => ({size}), [size])

  return (
    <Rover {...roverProps}>
      <MenuContext.Provider value={menu}>
        <ul css={cssMenu} className={className} onKeyDown={keyDownHandler} role="menu" {...rest}>
          {children}
        </ul>
      </MenuContext.Provider>
    </Rover>
  )
}
