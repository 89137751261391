import Landing from '../landing/Landing'

import Page from './Page'

export default function Home(): JSX.Element {
  return (
    <Page title="Home">
      <Landing />
    </Page>
  )
}
