import {useReducer, useEffect, useMemo} from 'react'

import me from '../api/me'
import logout from '../api/logout'
import assertNever from '../utils/assertNever'
import refreshAccessToken from '../api/refreshAccessToken'

import UserContext, {User, UserContextInterface} from './UserContext'

type UserAction = {type: 'logout'} | {type: 'updateUser'; user: User}

interface UserState {
  user?: User
  _state: UserContextInterface['_state']
}

function userReducer(_state: UserState, action: UserAction): UserState {
  switch (action.type) {
    case 'updateUser':
      return {_state: 'known', user: action.user}
    case 'logout':
      return {_state: 'known'}
    default:
      return assertNever(action, 'Unknown action type')
  }
}

function getUser(dispatch: (action: UserAction) => void): () => void {
  let canceled = false

  me()
    .then((user) => {
      if (canceled) return undefined
      dispatch({type: 'updateUser', user})

      // update the access token immediately since user may be returning to the page with
      // session cookie and access token could be expired already
      if (!user.refreshToken) return undefined
      return refreshAccessToken(user.refreshToken).then(
        ({accessToken}) =>
          !canceled && dispatch({type: 'updateUser', user: {...user, token: accessToken}})
      )
    })
    .catch(() => !canceled && dispatch({type: 'logout'}))

  return () => {
    canceled = true
  }
}

function triggerUpstreamLogout(dispatch: (action: UserAction) => void): void {
  // user should already be unset, but reset the state on an error just in case
  logout().catch((error) => {
    // TODO lumberjack
    // eslint-disable-next-line no-console
    console.error(`Error logging out ${error}`)
    dispatch({type: 'logout'})
  })
}

export default function UserProvider({children}: {children: React.ReactNode}): JSX.Element {
  const [{user, _state}, dispatch] = useReducer(userReducer, {_state: 'unknown'})

  useEffect(() => {
    // get the user on first load
    const cancel = getUser(dispatch)
    return () => {
      cancel()
    }
  }, [])

  // refresh access token every 15 minutes if user hasn't logged out to avoid access token expiry
  useEffect(() => {
    let isCurrent = true
    const timeoutId = window.setTimeout(
      () => {
        if (!user?.refreshToken) return
        refreshAccessToken(user.refreshToken).then(
          ({accessToken}) =>
            isCurrent && dispatch({type: 'updateUser', user: {...user, token: accessToken}})
        )
      },
      15 * 60 * 1000 // 15 minutes
    )

    return () => {
      window.clearTimeout(timeoutId)
      isCurrent = false
    }
  }, [user])

  const userContextValue = useMemo(
    () => ({
      user,
      _state,
      logout() {
        dispatch({type: 'logout'})
        triggerUpstreamLogout(dispatch)
      },
    }),
    [_state, user]
  )

  return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>
}
