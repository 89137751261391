import {css} from '@emotion/react'
import {IconChevronRight} from '@kensho/icons'

import Link, {LinkProps} from './Link'

interface GraphicElementProps {
  icon?: React.ReactNode
  heading?: string
  description?: string
  link?: LinkProps
  label?: string
}

const graphicElementCss = css`
  text-align: center;
  padding: 10px;
  @media screen and (max-width: 600px) {
    display: flex;
    margin-top: 10px;
  }
`

const contentCss = css`
  margin-top: 6px;
  line-height: 20px;
  @media screen and (max-width: 600px) {
    margin: 0 12px 0 24px;
    display: block;
    min-width: 100px;
    text-align: left;
    padding: 0;
  }
`

const linkCss = css`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 600px) {
    justify-content: flex-start;
    margin-top: 10px;
  }
`

const iconCss = css`
  display: inline-flex;
  justify-content: center;
  @media screen and (max-width: 600px) {
    justify-content: top;
    margin-left: 0;
  }
`

const headingCss = css`
  margin: 0 0 6px 0;
`

export default function GraphicElement(props: GraphicElementProps): JSX.Element {
  const {icon, heading, description, link, label} = props

  return (
    <div css={graphicElementCss}>
      <div css={iconCss}>{icon}</div>
      <div css={contentCss}>
        <h4 css={headingCss}>{heading}</h4>
        {description}
        {link && (
          <Link {...link} css={linkCss}>
            {label}
            <IconChevronRight size={20} />
          </Link>
        )}
      </div>
    </div>
  )
}
